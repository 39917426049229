export const norionBlack = '#2E2129';
export const norionBirch = '#F7F5F0';
export const norionGrey200 = '#F3F5F6';
export const norionGrey = '#E7EBEE';
export const norionMidGrey = '#CCCCCC';
export const norionGrey500 = '#999999';
export const norionGranite = '#63615E';

export const colors = {
    black: norionBlack,
    darkGray: norionGranite,
    mediumGray: norionGrey500,
    lightGray: norionMidGrey,
    offWhite: norionMidGrey,
    lightOffWhite: norionGrey200,
    white: norionBirch,

    lavender: norionGrey,
    purple: norionBlack,
    green: '#198038',
    red: '#DA1E28',
    blue: '#0F62FE',
    yellow: '#F1C21B',
    darkIndigo: norionBlack,
    pink: '#E30B80',
    darkPurple: '#28154E',

    disabledText: '#3B3B3D !important',
    disabledBackground: '#999999 !important',
    sidebarBlue: norionBlack,
    sidebarBlueLight: norionBlack,
    sidebarBlueDark: norionBlack,
};

const fontStackMedium = 'Aeonik Pro Medium, sans-serif';
const fontStackRegular = 'Aeonik Pro, sans-serif';

export const fonts = {
    desktop: {
        small: `14px/16px ${fontStackRegular}`,
        medium: `16px/21px ${fontStackRegular}`,
        large: `18px/21px ${fontStackMedium}`,
        xl: `29px/34px ${fontStackMedium}`,
        xxl: `40px/45px ${fontStackMedium}`,
    },
    mobile: {
        small: `14px/16px ${fontStackRegular}`,
        medium: `16px/20px ${fontStackRegular}`,
        large: `18px/24px ${fontStackMedium}`,
        xl: `20px/24px ${fontStackMedium}`,
        xxl: `24px/28px ${fontStackMedium}`,
    },
};

export const breakpoints = {
    mobileAndLower: '@media only screen and (max-width: 700px)',
    tabletAndLower: '@media only screen and (max-width: 1023px)',
};

export const borderRadius = {
    small: 4,
    large: 12,
};