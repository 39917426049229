import { isValid as isValidSwedishPersonalIdentityNumber } from 'swedish-personal-identity-number-validator';
import { registerLocale, getNames as getCountryNames } from 'i18n-iso-countries';

registerLocale(require('i18n-iso-countries/langs/sv.json'));

export default {
    countryCode: 'se',
    locale: 'sv',
    currency: 'SEK',
    loginHint: 'sbid-v4',
    signicatMethod: 'SwedishBankId',
    companyLoansComplianceProductId: Number('#{complianceIdSE}#'),
    factoringComplianceProductId: 79,
    dateFormat: 'yyyy-MM-dd',
    personalIdentityNumberPlaceholder: 'ÅÅÅÅMMDDXXXX',
    personalIdentityNumberValidator: isValidSwedishPersonalIdentityNumber,
    months: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
    countries: getCountryNames('sv'),
};
