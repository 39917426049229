import 'core-js/stable';
import 'abort-controller/polyfill';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import './norion.css';

import './common/fonts/AeonikPro-Medium.woff';
import './common/fonts/AeonikPro-Regular.woff';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import styled, { CollectorPortalFramework } from 'collector-portal-framework';
import { CorporatePortal } from './common/App';
import * as norionTheme from './common/theme/norion';

import { LicenseManager } from 'ag-grid-enterprise';
import {norionBirch} from "./common/theme/norion";
LicenseManager.setLicenseKey('#{agGridLicenseKey}#');

const App = () => {
    return (
        <BrowserRouter>
            <CollectorPortalFrameworkContainer />
        </BrowserRouter>
    );
};

const CollectorPortalFrameworkContainer = () => {
    
    const BackgroundColorContainer = styled.div({
        backgroundColor: norionBirch,
        height: '100vh'
    })

    return (
        <BackgroundColorContainer>
            <CollectorPortalFramework theme={norionTheme}>
                <CorporatePortal/>
            </CollectorPortalFramework>
        </BackgroundColorContainer>
    );
};

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
