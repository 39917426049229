import { isValid as isValidFinnishPersonalIdentityCode } from 'finnish-personal-identity-code-validator';
import { registerLocale, getNames as getCountryNames } from 'i18n-iso-countries';

registerLocale(require('i18n-iso-countries/langs/fi.json'));

export default {
    countryCode: 'fi',
    locale: 'fi',
    currency: 'EUR',
    loginHint: 'ftn',
    signicatMethod: 'FinnishTrustNetwork',
    companyLoansComplianceProductId: Number('#{complianceIdSE}#'),
    factoringComplianceProductId: 79,
    dateFormat: 'd.M.yyyy',
    personalIdentityNumberPlaceholder: 'PPKKVV-NNNT',
    personalIdentityNumberValidator: isValidFinnishPersonalIdentityCode,
    months: [
        'Tammikuu',
        'Helmikuu',
        'Maaliskuu',
        'Huhtikuu',
        'Toukokuu',
        'Kesäkuu',
        'Heinäkuu',
        'Elokuu',
        'Syyskuu',
        'Lokakuu',
        'Marraskuu',
        'Joulukuu',
    ],
    countries: getCountryNames('fi'),
};
