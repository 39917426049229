export default {
    authErrors: {
        correlation_failed: 'Inloggningen misslyckades. Var god försök igen.',
        invalid_request: 'Inloggningen misslyckades. Var god försök igen. Notera att personnumret ska anges enligt format ÅÅÅÅMMDD-NNNN.',
        session_expired: 'Du har blivit utloggad för att du varit inaktiv i mer än 15 minuter.',
    },
    factoring: 'Fakturaköp',
    agreement: 'Avtal {0}',
    loanApplicationNumber: 'Ansökningsnummer',
    applicant: 'Kredittagare',
    provisionalInterestRate: 'Prel. ränta',
    interestRate: 'Ränta',
    guaranteeInterest: 'Garantiprovision',
    provisionalDuration: 'Prel. löptid',
    requestedDuration: 'Önskad löptid',
    grantedDuration: 'Beviljad löptid',
    duration: 'Löptid',
    processing: 'Under behandling',
    processingDescription: 'Vi behandlar er ansökan och återkommer inom kort med mer information.',
    readyToSign: 'Redo att signeras',
    readyToSignDescription: 'Ansökan har blivit godkänd och är redo att signeras av firmatecknare.',
    waitingForCompletion: 'Inväntar komplettering',
    supplementaryInfo: 'Komplettering',
    debenture: 'Skuldebrev',
    approved: 'Godkänd',
    closed: 'Stängd',
    applicationRejected: 'Ansökan avslagen',
    applicationRejectedDescription: 'Er ansökan har blivit avslagen. Kontakta kundtjänst för mer information.',
    genericError: 'Ett fel uppstod',
    maintenance: 'På grund av systemunderhåll kan informationen inte visas just nu',
    cancel: 'Avbryt',
    completed: 'Färdig',
    logIn: 'Logga in',
    logInWithBankId: 'Logga in med BankID',
    logInWithMobileBankId: 'Logga in med mobilt BankID',
    logOut: 'Logga ut',
    thisWebSiteIsForCorporateCustomers: 'Denna sida vänder sig till företagskunder på Norion Bank',
    generalCustomerServicePhoneNumber: '010 - 161 00 00',
    corporateCustomerServicePhoneNumber: '010 - 161 04 36',
    customerServiceEmail: 'foretagslan@norionbank.se',
    ifYouNeedPrivateHelpDescription: 'Om du har en fråga som gäller dig som privatperson, vänligen ring',
    ifYouNeedCorporateHelpDescription: 'Om ni har frågor rörande era befintliga företags- eller fastighetskrediter, kontakta oss på',
    orVisitUsAt: 'eller besök oss på',
    collectorURL: 'http://collector.se',
    walleyURL: 'http://walley.se',
    startPage: 'Översikt',
    doYouNeedHelp: 'Behöver du hjälp? Kontakta kundservice på',
    or: 'eller',
    genericConfirmationQuestion: 'Är du säker på att du vill ta bort?',
    genericConfirmationCancelQuestion: 'Är du säker på att du vill avbryta? Dina inmatade värden kommer försvinna.',
    add: 'Lägg till',
    addEmail: 'Lägg till email',
    save: 'Spara',
    edit: 'Ändra',
    remove: 'Ta bort',
    close: 'Stäng',
    month: 'månad',
    months: 'månader',
    personalIdentityNumber: 'Personnummer',
    invalidPersonalIdentityNumber: 'Personnumret är inte giltigt',
    nextStep: 'Spara och gå vidare',
    nextStepWithoutSaving: 'Gå vidare',
    yes: 'Ja',
    no: 'Nej',
    miscellaneous: 'Allmänt',
    bank: 'Bank',
    amount: 'Belopp',
    currency: 'Valuta',
    documents: 'Dokument',
    verificationDocuments: 'Dokument för att verifiera konto',
    attachments: 'Filuppladdning',
    signing: 'Granska och signera',
    fortnox: 'Koppla affärssystem',
    signDebenture: 'Signera skuldebrev',
    signFactoringAgreement: 'Signera avtalen',
    readTheDebenture: 'Läs skuldebrev',
    letter: 'Brev',
    clCompleteApplicationFor: {
        creditType: {
            DevelopmentLoan: 'Slutför låneansökan för {0}'
        },
    },
    clCompleteApplication: {
        creditType: {
            DevelopmentLoan: 'Slutför låneansökan'
        },
        button: 'Slutför ansökan',
    },
    factApplicationFor: 'Ansökan om fakturaköp för {0}',
    factApplication: 'Ansökan om fakturaköp',
    dataProtectionDisclaimer:
        'I samband med att du ansöker om en kredit hos oss kommer vi att behandla dina personuppgifter. Vi behandlar endast dina personuppgifter så länge det finns en rättslig grund för det. Det övergripande ändamålet med behandlingen är att kontrollera och pröva din ansökan om att bli kund och att därefter hantera och utföra det avtal som uppstått. <a href="https://www.collector.se/om-collector/integritet-och-sakerhet/dataskydd/" target="_blank" rel="noopener noreferrer">Läs mer om hur Collector hanterar dina personuppgifter på Collectors Dataskyddspolicy.</a>',
    dataProtectionDisclaimerFactoring:
        'I samband med att du ansöker om fakturaköp hos oss kommer vi att behandla dina personuppgifter. Vi behandlar endast dina personuppgifter så länge det finns en rättslig grund för det. Det övergripande ändamålet med behandlingen är att kontrollera och pröva din ansökan om att bli kund och att därefter hantera och utföra det avtal som uppstått. <a href="https://www.collector.se/om-collector/integritet-och-sakerhet/dataskydd/" target="_blank" rel="noopener noreferrer">Läs mer om hur Collector hanterar dina personuppgifter på Collectors Dataskyddspolicy.</a>',
    companyType: 'Företagstyp',
    digitallySign: 'Signera med BankID',
    loan: 'Lån',
    noApplications: {
        DevelopmentLoan: 'Du har inga låneansökningar för tillfället.'
    },
    companyLoansUrl: {
        DevelopmentLoan: 'https://www.collector.se/foretag/foretagsfinansiering/tillvaxtlanet/'
    },
    readMore: {
        DevelopmentLoan: 'Läs mer om Tillväxtlånet'
    },
    more: 'Mer',
    contactPerson: 'Kontaktperson',
    contactPersonDescription:
        'Den person som kommer vara kontaktperson hos er och som Collector Bank kan vända sig till om det skulle behövas.',
    companyAddress: 'Företagsadress',
    emailInvalid: 'E-postadressen är inte giltig',
    telephoneNumberInvalid: 'Numret är inte giltligt. Ange +46xxxxxxxxx eller +47xxxxxxxx.',
    name: 'Namn',
    emailAddress: 'E-postadress',
    emailAddresses: 'E-postadresser',
    email: 'E-mail',
    countryCode: 'Nationalitet',
    guarantors: 'Borgensmän',
    guarantorDescription:
        'Att gå i borgen för någon innebär att man är skyldig att betala de skulder kredittagaren iklätt sig i det fall denne inte kan betala eller har några utmätningsbara tillgångar.',
    telephoneNumber: 'Mobiltelefonnummer',
    telephoneNumberIncludingCountryCode: '+46/+47 xx xxx xx xx',
    signatory: 'Firmatecknare',
    guarantor: 'Borgensman',
    companyLoansApproved: 'Ert skuldebrev är redo att signeras.',
    factApproved: 'Er ansökan om fakturaköp har blivit godkänd. För att använda tjänsten måste Ni signera nedanstående avtal:',
    factSignTitle: 'Signera',
    factSign: 'Genom att signera denna ansökan intygar du att den inmatade informationen är korrekt.',
    factoringUrl: 'https://www.collector.se/foretag/foretagsfinansiering/factoring/',
    noCurrentApplications: 'Du har inga ansökningar för tillfället.',
    factReadMore: 'Läs mer om fakturaköp',
    welcome: 'Välkommen!',
    factApplicationExists: 'Du har en ansökan om fakturaköp.',
    factMultipleApplications: 'Du har flera ansökningar om fakturaköp.',
    goToApplications: 'Gå till ansökningar för att starta kompletteringen',
    goToApplication: 'Gå till ansökan',
    bothApplications: 'Du har ansökningar om både fakturaköp och lån. Du når dina ansökningar genom huvudmenyn.',
    companyLoansSignSuccessful: {
        supplementaryInfo: 'Vi kommer handlägga ditt ärende och återkommer inom kort.',
        deniedAfterSupplementaryInfoSigned:
            'Efter granskning av er komplettering måste vi tyvärr meddela att vi i dagsläget inte kan erbjuda er lån. Vid en förbättrad finansiell ställning är ni välkomna att ansöka på nytt. Vid frågor, kontakta oss på telefon 010 - 161 00 00.',
        debenturePartlySigned: 'När valda firmatecknare har signerat skuldebrevet kommer vi handlägga ert ärende och återkomma.',
        debentureFullySigned: 'Vi kommer handlägga ditt ärende och återkommer inom kort.',
    },
    factoringApplicationSignSuccessful: 'Tack! Vi kan nu granska din ansökan. Vi återkommer inom kort med mer information.',
    factoringTaskSignSuccessful:
        'Tack! De avtal du signerat kommer att skickas till dig via e-post och vi återkommer inom kort med mer information.',
    signAborted: 'Signering avbruten.',
    addApplicantToAdmin: {
        title: 'Administratörer',
        question: 'Vill du lägga till {0} som administratör på företaget?',
        yes: 'Ja, lägg till som administratör',
        no: 'Nej, gå vidare till signering',
        finish: '{0} är nu tillagd som administratör och kommer att kunna se företagets aktiva engagemang framöver.',
        errorHeading: 'Misslyckades att lägga till administratör',
        errorDescription:
            'Vi kunde tyvärr inte lägga till {0} som administratör för företaget. Vänligen kontakta oss efter att ni har slutfört ansökan.',
        moveToSigning: 'Gå vidare till signering',
        description: 'En administratör kommer att kunna se och administrera bolagets alla engagemang.',
    },
    clSummary: {
        textToSign: 'Härmed intygar jag att kompletterad information på ansökan {0} är korrekt.',
        legalIntro: 'Härmed intygar jag att ifylld information är korrekt och att jag har befogenheter att slutföra låneansökan för {0}.',
        applicaitonCompleteInfo:
            'Efter genomförd komplettering och signering kommer din ansökan att granskas. Firmatecknare och eventuella borgensmän för {0} kommer behöva signera skuldebrev och borgensavtal. Vi återkommer inom kort med mer information.',
        accountNumber: 'Kontonummer',
    },
    fileUpload: {
        pleaseAttach: 'Vänligen bifoga följande dokument:',
        incomeStatement: 'Resultaträkning (senast avslutade verksamhetsår eller nyare)',
        balanceSheet: 'Balansräkning (senast avslutade verksamhetsår eller nyare)',
        uploadMultiple: 'För att ladda upp flera filer, håll ner CTRL/CMD-knappen och markera alla filer.',
        notPasswordProtected: 'Dokumenten får ej vara lösenordsskyddade.',
        validFileExtensions: 'Godkända filformat: {0}.',
        maxFileSize: 'Max filstorlek är 30 MB per fil',
        uploadFile: 'Ladda upp fil',
        pleaseConsider: 'Att tänka på...',
    },
    clQuestions: {
        new: 'Nystartat bolag',
        old: 'Befintligt företag',
        takeOver: 'Övertag av företag',
        companyType: 'Företagstyp',
        loan: 'lån',
        existingLoans: 'Har företaget för tillfället krediter hos andra banker/kreditgivare?',
        investingInPremises: 'Investering i lokaler',
        expandInventory: 'Utöka varulager',
        homepageMarketing: 'Hemsida/Marknadsföring',
        purchaseOfMachineryOrEquipment: 'Inköp av maskiner/utrustning',
        refinancingOfLoans: 'Refinansiering av lån',
        hireStaff: 'Anställa personal',
        liquidityForExpansion: 'Likviditet för expansion',
        generallyIncreasedLiquidity: 'Generellt ökad likviditet',
        unexpectedExpenses: 'Oväntade utgifter',
        businessOverviewStatement: 'Verksamhetsbeskrivning & affärsidé',
        futurePlans: 'Framtidsplaner',
        loanPurposes: 'Syfte med krediten',
        numberOfEmployees: 'Antal anställda',
        turnover: 'Omsättning senaste 12 månader ({0})',
        profit: 'Resultat senaste 12 månader ({0})',
    },
    factSummary: {
        textToSign: 'Härmed intygar jag att kompletterad information är korrekt.',
        title: 'Komplettering',
    },
    factQuestions: {
        accountNumber: 'Vilket kontonummer vill ni få utbetalningar i {0} på?',
        businessDescription: 'Kort beskrivning av verksamheten',
        otherInformation: 'Övrig information',
        otherInformationDescription: 'Här kan du fylla på med information som inte täcks av våra frågor eller kompletterande information.',
        shareOfBusinessCustomers: 'Hur stor del av er fakturering sker mot företag?',
        averageDueDays: 'Vilken är era genomsnittliga betalningsvillkor?',
        name: 'Namn',
        emailAddress: 'E-postadress',
        telephoneNumber: 'Mobiltelefonnummer',
        address1: 'Addressrad 1',
        address2: 'Addressrad 2',
        zipCode: 'Postnummer',
        city: 'Stad',
        companyExisted: 'Har företaget funnits i mer än 12 månader?',
        turnover: 'Hur mycket förväntas ni omsätta i år? ({0})',
        requestedLimit: 'Vad är ert önskade kreditutrymme? ({0}) (valfritt)',
        requestedLimitDescription: 'Här anger ni hur stor kredit ni tror er behöva.',
        percent: 'ca {0} %',
        billInAdvance: 'Tillämpar ni förskottsfakturering?',
        changeSellBillInAdvanceInvoices: 'Avser ni sälja fakturor till oss som ni har förskottsfakturerat?',
        billInAdvanceWarning: 'Observera att vi endast acceptrerar fakturor som är utställda efter att hela arbetet/tjänsten är utförd.',
        beforeDoneInvoice: 'Förekommer det att ni fakturerar innan 100 % av arbetet är utfört?',
        days: '{0} dagar',
    },
    factFileUploader: {
        bankgiroScreenshot: {
            label: 'Bevis på ägandeskap av bankgiro/bankkonto',
            description:
                'Ett utdrag från internetbanken eller från bankgirot.se där det framgår att bankgirot/bankkontot tillhör ert bolag. Detta behöver vi för att veta vilket bankkonto eller bankgiro som ni vill ha pengar utbetalt till och att det är ni som är kontoägare.',
        },
        balanceSheetYearToDate: {
            label: 'Balansräkning för nuvarande år',
            description:
                'En sammanställning över nuvarande års balansräkning. Vi behöver dessa siffror för att kunna göra en bedömning av er finansiella kreditvärdighet.',
        },
        incomeStatementYearToDate: {
            label: 'Resultaträkning för nuvarande år',
            description:
                'En sammanställning över nuvarande års resultaträkning. Vi behöver dessa siffror för att kunna göra en bedömning av er finansiella kreditvärdighet.',
        },
        annualReportLastYear: {
            label: 'Årsredovisning för föregående år',
            description:
                'En sammanställning över föregående års resultat- och balansräkning. Vi behöver dessa siffror för att kunna göra en bedömning av er finansiella kreditvärdighet.',
        },
        confirmationOfPaidCapital: {
            label: 'Bekräftelse på inbetalt kapital',
            description:
                'Ett intyg från er bank som visar på att ert bolag har betalt in aktiekapitalet. Vi har som krav att aktiekapitalet ska vara inbetalt och behöver därför denna bekräftelse som intyg.',
        },
        proofOfOwnership: {
            label: 'Registreringsbevis',
            description:
                'Ett registreringsbevis från bolagsverket. Registreringsbeviset behöver vi för att säkerställa att ert företag har blivit registrerat.',
        },
    },
    timeOutWarning: {
        heading: 'Du håller på att bli utloggad',
        body: 'Du håller på att bli utloggad på grund av inaktivitet. Vill du fortsätta vara inloggad?',
        confirm: 'Ja tack, håll mig inloggad',
        discard: 'Nej tack',
    },
    timeOutWarningSessionMaxTime: {
      body: 'Om 15 minuter kommer du loggas ut, logga ut och logga in igen för att starta en ny session.',
      confirm: 'Ok', 
      discard: 'Logga ut',
    },
    sign: 'Signera',
    isSigning: 'Under signering',
    alreadySigned: 'Redan signerad',
    signatureComplete: 'Tack!',
    signatureCompleteButDenied: 'Vi kan tyvärr inte erbjuda er lån',
    debentureComplete: 'Tack!',
    agreementSignatureComplete: 'Tack för att du signerade avtalet!',
    optional: 'valfritt',
    status: 'Status',
    detailedInfo: 'Detaljerad information',
    completeLoanApplication: 'Slutför låneansökan',
    completeDigitalFactoring: 'Slutför ansökan',
    completeDigitalFactoringApplication: 'Slutför ansökan om fakturaköp',
    moveAlong: 'Gå vidare',
    previous: 'Föregående',
    generalInfo: 'Allmän information',
    complianceQuestions: 'Kompletterande frågor (AML/PEP)',
    reviewAndComplete: 'Signera ansökan',
    almostFinished: {
        creditType: {
            DevelopmentLoan:
                'Klicka på slutför låneansökan för att komplettera med de sista uppgifterna. Därefter gör vi en slutgiltig bedömning.'
        },
        description: 'Klicka på slutför ansökan för att komplettera med de sista uppgifterna. Därefter gör vi en slutgiltig bedömning.',
    },
    almostFinishedFactoring:
        'Klicka på slutför ansökan för att komplettera med de sista uppgifterna. Därefter gör vi en slutgiltig bedömning.',
    requestedLoanAmount: 'Önskat kreditbelopp',
    grantedLoanAmount: 'Beviljat kreditbelopp',
    provisionalGrantedLoanAmount: 'Prel. beviljat kreditbelopp',
    companyName: 'Företagsnamn',
    organisationNumber: 'Organisationsnummer',
    type: 'Typ',
    signatoryCombinations: {
        signatories: 'Firmatecknare',
        choiceOfSignatories: 'Val av firmatecknare',
        chooseSignatoriesPlaceholder: 'Välj firmatecknare',
        chooseConstellation: 'Välj firmatecknare som skall signera avtalet.',
        contactInfoHeading: 'Kontaktuppgifter för {0}',
        noCombinationsFound: 'Vi kunde inte identifiera vilka firmatecknare som finns på ert bolag. Var vänlig ring kundtjänst.',
        procuration: 'Prokura',
    },
    loanApplications: 'Låneansökningar',
    applications: 'Ansökningar',
    adviceMethod: 'Aviseringsmetod',
    companyLoanDetails: {
        companyDetails: {
            title: 'Företagsinformation',
            contactPerson: 'Kontaktperson för företaget',
            choseAdviceMethod: 'Välj aviseringsmetod',
            adviceEmail: 'E-postadress för avisering',
        },
        invoices: 'Fakturor',
        denyOthers: {
            title: 'Flera pågående ansökningar',
            cancel: 'Nej, avbryt',
            description:
                'Vi noterar att du har flera pågående ansökningar just nu. Väljer du att gå vidare med den här ansökan, kommer resterande ansökningar att automatiskt stängas. Vill du fortsätta till signering med den här ansökan?',
            descriptionWithCompany:
                'Vi noterar att du har flera pågående ansökningar just nu. Väljer du att gå vidare med den här ansökan, kommer resterande ansökningar att automatiskt stängas. Vill du fortsätta till signering med den här ansökan från {0}?',
            approve: 'Ja, gå vidare till signering',
        },
        upcoming: 'Kommande',
        notified: 'Aviserade',
        ocrNumber: 'OCR-nummer',
        elapsedAmount: 'Förfallet belopp',
        capitalDebt: 'Kapitalskuld',
        originalAmount: 'Ursprungligt belopp',
        guaranteeAmount: 'Garantibelopp',
        currentDebt: 'Aktuell skuld',
        registrationNumber: 'Lånenummer',
        facilityFee: 'Facilitetsavgift',
        creditNumber: 'Avtalsnummer',
        signedDate: 'Avtalsdatum',
        endDate: 'Slutdatum',
        appliedThrough: 'Ansökt via',
        overdueInvoicesHeading: 'Förfallen faktura',
        overdueInvoicesMessage:
            'Du har ett förfallet belopp på {0}. Betala in det förfallna beloppet omgående för att undvika ytterligare avgifter.',
        invoicingInfo: 'Avisering',
        invoicingMethod: 'Aviseringssätt',
        invoicingRecipients: 'Aviseringsmottagare',
        invoicingUpdateFailed: 'Misslyckades att uppdatera aviseringssätt',
        invoicingWarning: 'Observera! Valt aviseringssätt avser endast fakturor avseende lån/krediter.',
        changeInvoicingMethod: 'Ändra aviseringsmetod',
        loanStatuses: {
            Created: 'Registrerad',
            Activated: 'Aktiv',
            Terminated: 'Avslutad',
            MarkedForDebtCollection: 'Inkasso',
            PendingTermination: 'Uppsagd',
        },
        capitalOverview: {
            show: 'Visa kapitalöversikt',
            title: 'Kapitalöversikt',
            stopShow: 'Dölj kapitalöversikt',
            Amortisation: 'Amortering',
            Disbursement: 'Utbetalning',
            CapitalizedInterest: 'Kapitaliserad ränta',
            CapitalizedFacilityFee: 'Kapitaliserad facilitetsavgift',
            debt: 'Kapitalskuld',
        },
        invoicePaymentStatuses: {
            Unpaid: 'Obetald',
            PartlyPaid: 'Delbetald',
            Paid: 'Betald',
            Credited: 'Krediterad',
        },
        invoiceStatuses: {
            NotNotified: 'Ej aviserad',
            Notified: 'Aviserad',
            Overdue: 'Förfallen',
            ReminderSent: 'Påminnelse skickad',
        },
        invoiceCopy: {
            buttonText: 'Skicka om avi',
            title: 'Skicka om avi',
            send: 'Skicka om avi',
            failed: 'Misslyckades att skicka om avi',
            success: 'Återsändning godkändes',
            successDetails: 'Vi kommer nu att skicka om den valda fakturan. Det kan ta upp till 1h innan den dyker upp i inkorgen.',
            maximalAmountReached: 'Max 2 kopior per dag',
        },
        isDue: 'Förfaller',
        leftToPay: 'Kvar att betala: ',
        invoiceDetails: { description: 'Beskrivning', type: 'Typ', amount: 'Belopp' },
        itemType: {
            HandlingFee: 'Hanteringsavgift',
            ArrangementFee: 'Uppläggningsavgift',
            PrepaymentFee: 'Avgift förtida lösen',
            OtherFee: 'Annan avgift',
            FacilityFee: 'Facilitetsavgift',
            ReminderFee: 'Påminnelseavgift',
            Expense: 'Utlägg',
            Installment: 'Amortering',
            Interest: 'Ränta',
            PenaltyInterest: 'Dröjsmålsränta',
            Rounding: 'Avrundning',
            Disbursement: 'Utbetalning',
            Payment: 'Inbetalning',
            Notification: 'Aviavgift',
            Aconto: 'Aconto',
            GuaranteeInterest: 'Garantiprovision',
            
        },
        creditType: {
            Company: 'Företagslån',
            Property: 'Fastighetskredit',
            EntryLoan: 'Lån',
            EmergencyLoan: 'Lån',
            DevelopmentLoan: 'Tillväxtlån',
            ECommerceLoan: 'Lån',
            LeaseLoan: 'Lån',
            PropertyPrivate: 'Lån',
            PrivateCredit: 'Lån',
            EmployeeCredit: 'Lån',
            BankGuarantee: 'Bankgaranti',
            BankGuaranteeProperty: "Bankgaranti (Fastighet)"
        },
        creditStatus: { Draft: 'Utkast', Active: 'Aktivt', Closed: 'Avslutat' },
        noNotifiedInvoices: 'Ni har inga aviserade fakturor.',
        noNotNotifiedInvoices: 'Ni har inga kommande fakturor.',
        transactionTab: {
            history: 'Historik',
            withdrawal: 'Uttag',
            stopWithdrawal: 'Stoppa utbetalning',
        },
    },
    clTransactions: {
        noTransactions: 'Det finns inga transaktioner i detta datumintervallet',
    },
    clTransactionReport: {
        title: 'Ladda ner transaktionslista',
        downloadText: 'Ladda ner som fil',
        csv: {
            date: 'Datum',
            type: 'Typ',
            amount: 'Belopp',
            currency: 'Valuta',
        },
        type: { Payment: 'Inbetalning', Disbursement: 'Utbetalning' },
    },
    stopWithdrawal: {
        heading: 'Stoppa utbetalning',
        question: 'Är du  säker på att du vill stoppa utbetalningen?',
        confirm: 'Ja, stoppa utbetalningen',
        discard: 'Nej, avbryt',
        toolTip: {
            CancellationInitiated: 'Försök att avbryta utbetalning påbörjat',
            CancellationFailed: 'Misslyckades att avbryta utbetalning',
        },
        done: 'Klar',
        info:
            'Begäran om att avbryta utbetalningen är nu initierad. Vi kommer att försöka stoppa den så snart som möjligt. Du kan se utvecklingen under pågående transaktioner.',
        error: 'Det gick tyvärr inte att avbryta utbetalningen i detta skede. ',
        errorHeading: 'Misslyckades att stoppa utbetalning',
    },
    withdrawalQuestions: {
        dateSelect: { asap: 'Snarast', other: 'Annat datum', future: 'Framtida datum' },
        date: { label: 'Datum för uttag' },
        withDrawalAmount: 'Belopp',
        toAccount: 'Till konto',
        submit: 'Bekräfta uttag',
        noAccount: 'Inget bankkonto registrerat på krediten. Kontaka kundtjänst för mer information',
        confirm: 'Godkänn',
        cancel: 'Avbryt utbetalning',
        confirmQuestion: 'Granska och godkänn',
        completed: {
            approved: 'Överföringen är godkänd',
            receipt: 'Kvittens',
            done: 'Klar',
        },
        error: { denied: 'Utbetalningen misslyckades' },
        disabled: 'Uttag är för tillfället inaktiverat. Kontakta kundtjänst för mer information.',
    },
    disbursementStatus: {
        Initiated: 'Initierad',
        CancellationFailed: 'Initierad',
        CancellationInitiated: 'Initierad',
        Completed: 'Utbetald',
        Cancelled: 'Stoppad',
        Denied: 'Nekad',
        Failed: 'Misslyckad',
    },
    documentsView: {
        noDocuments: 'Ni har inga dokument att hämta.',
        engagementReportsHeader: 'Engagemangsrapport',
        noEngagements: 'Inga engagemang kopplat till företaget',
        download: 'Ladda ned',
        companySelect: 'Välj företag',
        selectToDate: 'Engagemang t.o.m.',
        includeTerminated: 'Inkludera avslutade lån',
        agreements: 'Avtal',
        selectFromDate: 'Engagemang fr.o.m.',
        includeFromDate: 'Specificera datumintervall',
        language: 'Språk',
        swedish: 'Svenska',
        norwegian: 'Norska',
        finnish: 'Finska',
        english: 'Engelska',
    },
    userView: {
        noActiveCompanies: 'Du har för tillfället inga aktiva engagemang att administrera.',
        pageHeader: 'Användare',
        heading: 'Administrera användare',
        companyHeading: 'Administrera användare för {0}',
        role: { Signatory: 'Firmatecknare', Admin: 'Administratör' },
        addUser: 'Lägg till användare',
        duplicateUser: 'Användaren finns redan',
        confirmation: 'Ja tack, ta bort',
        userConfirmationQuestion: 'Är du säker på att du vill ta bort denna användaren?',
        telephoneNumberInvalid: 'Ange format +46 xxx xx xx xx för svensk användare och +47 xx xx xx xx för norsk användare.',
    },
    fortnoxApiKey: {
        apiKey: 'API-nyckel för integration Collector Factoring',
        connect: 'Koppla',
        approvedHeader: 'Kopplat!',
        approved: 'Vi har kopplat upp hos mot ert konto hos Fortnox.',
        information: 'Hur hittar jag min API-nyckel?',
        informationLink: 'https://support.fortnox.se/hc/sv/articles/208332265-Integrera-Fortnox-med-ett-annat-system',
        error: 'Ett fel uppstod, vänligen försök igen',
        apikeyError: 'Kunde inte koppla upp oss mot Fortnox, var god kontrollera att ni inte skrivit fel och försök igen',
        pleaseWait: 'Vi jobbar på det, stäng inte din webbläsare.',
    },
    loading: 'Laddar...',
    sieUploader: {
        instructions:
            'För att kunna slutföra bedömningen behövs ditt bolags balans- och resultaträkning i form av en SIE-fil av typ 2, 3 eller 4. Denna kan du exportera ur ditt bokföringsprogram. Nedan finner du länkar med instruktioner hur en SIE-fil kan exporteras från de vanligaste affärssystemen.',
        manualUpload: 'Fortsätt utan att ladda upp SIE-fil',
        cancelManual: 'Ladda upp SIE-fil istället',
        heading: 'Ladda upp balans- och resultaträkning',
        success: 'Balans- och resultaträkning uppladdad!',
        errorParsingSieFile: 'Något gick fel. Kontrollera att du laddade upp en SIE-fil typ 2, 3 eller 4.',
        tooOldIncomeStatement: 'Resultaträkning måste innehålla perioder från innevarande eller föregående år.',
    },
    saftUploader: {
        instructions: '',
        manualUpload: '',
        cancelManual: '',
        heading: '',
        success: '',
        errorParsingSaftFile: '',
        tooOldIncomeStatement: '',
    },
    fileUploader: {
        error: {
            tooOldIncomeStatement: 'Resultaträkning måste innehålla perioder från innevarande eller föregående år.',
            errorParsingSaftFile: '',
            errorParsingSieFile: 'Något gick fel. Kontrollera att du laddade upp en SIE-fil typ 2, 3 eller 4.',
            failedToRemove: 'Något gick fel.',
        },
    },
    companyLoanTransactionType: { Disbursement: 'Utbetalning', Payment: 'Inbetalning' },
    transactions: 'Transaktioner',
    factClient: {
        client: 'Fakturaköpsavtal',
        clientSummary: 'Överblick',
        limit: { unusedLimit: 'Outnyttjad limit', usedLimit: 'Utnyttjad limit' },
        summary: {
            totalLimit: 'Total limit',
            debtAmount: 'Saldo avräkningskonto',
            openCreditnotes: 'Öppna kreditfakturor',
            openCreditnotesDescription:
                'Kreditnotor som mottagits men ännu inte nyttjats av slutkund. Beloppet har justerat Ert avräkningskonto',
            myInvoices: 'Mina fakturor',
            invoiceStatus: 'Fakturastatus',
        },
        factoringType: {
            buyAmount: 'Finansierade',
            recourseAmount: 'Finansierade m. regress',
            recoursedAmount: 'Återköpta',
            serviceAmount: 'Service',
            Buy: 'Finansierad',
            Denied: 'Ej finansierad',
            Recourse: 'Finansierad med regress',
            Service: 'Administration',
        },
        invoiceStatus: {
            openAmount: 'Ej förfallna',
            expiredInvoicesAmount: 'Förfallna',
            expiredInvoicesWithRemindersSentAmount: 'Påminnelse',
            debtCollectionAmount: 'Inkassomarkerad',
            open: 'Öppen',
            closed: 'Stängd',
        },
        creditnote: 'Kreditfaktura',
        invoices: 'Fakturor',
        invoiceDetails: {
            description: 'Beskrivning',
            itemNumber: 'Artikelnummer',
            amount: 'Belopp',
        },
        transactionCodes: {
            '1000': 'Transaktion',
            '1001': 'Adminstrationsavgift kreditfaktura',
            '1002': 'Kreditnota bokförd',
            '1003': 'Kreditnota kopplad till återköpt faktura eller servicefaktura',
            '1004': 'Betalning av servicefaktura',
            '1005': 'Finansierade fakturor',
            '1006': 'Återköp av faktura',
            '1007': 'Regressränta debiterad',
            '1008': 'Finansiering avbruten',
            '1009': 'Direktbetalning registrerad',
            '1010': 'Utbetalning',
            '1011': 'Årsavgift',
            '1012': 'Betalning av återköpt faktura',
            '1013': 'Administrationsavgift',
            '1014': 'Finansieringsavgift',
            '1015': 'Moms finansieringsavgift',
            '1016': 'Moms administrationsavgift',
        },
        transactionList: 'Transaktioner',
        transactionDetails: {
            reference: 'Referens',
            information: 'Information',
            transactionType: 'Transaktionstyp',
            currencyCode: 'Valuta',
            postingDate: 'Datum',
            transactionCode: 'Transaktionskod',
            amount: 'Belopp',
            downloadCsv: 'Ladda ner som fil',
        },
        noSoldInvoices: 'Här var det tomt! Här kommer dina fakturor att visas',
        noTransactions: 'Här var det tomt! Här kommer dina transaktioner visas',
        invoiceReasonCode: {
            '1001': 'Fakturan är mer än 10 dagar gammal',
            '1002': 'Framtida eller inkorrekt fakturadatum',
            '1003': 'Fakturan kan ej finansieras (utan regress) pga förhöjd kreditrisk',
            '1004': 'Fakturan kan ej finansieras pga att slutkunden är på obestånd',
            '1005': 'Fakturan kan ej finansieras då den ej ryms inom Er finansieringslimit',
            '1006': 'Fakturan kan ej finansieras med regress då den ej ryms inom Er regresslimit',
            '1007': 'Fakturan kan ej finansieras med regress pga den totala exponeringen mot slutkunden',
            '1008': 'Styrelsemedlem aktiv hos både klient och kund',
            '1009': 'Kund verksam i bransch som inte är tillåten',
            '2009': 'Betalningstiden är över 90 dagar.',
            heading: 'Orsak till finansieringsbeslut',
        },
        transactions: {
            searchTransactions: 'Sök på referens eller belopp...',
            filterTransactionType: 'Filtrera bland transaktionstyp',
        },
        status: {
            Active: 'Aktiv',
            Blocked: 'Blockerad',
            Terminated: 'Uppsagd',
            blockedInfo: 'Avtalet är temporärt blockerat och fakturaköpstjänsten är därför inaktiverad',
            terminatedInfo: 'Avtalet är uppsagt och inte längre aktivt.',
        },
        invoiceSearchAndFilter: {
            invoiceStatusFilterLabel: 'Status',
            invoiceTypeFilterLabel: 'Finansieringsbeslut',
            searchLabel: 'Sök på fakturanummer eller kund...',
        },
        sortHeader: 'Sorteringsordning',
        sortByInvoiceDateDesc: 'Fakturadatum fallande',
        sortByInvoiceDateAsc: 'Fakturadatum stigande',
        sortByDueDateDesc: 'Förfallodatum fallande',
        sortByDueDateAsc: 'Förfallodatum stigande',
        factoringTypeText: {
            Buy:
                'Denna faktura har överlåtits till Collector Bank som sköter distribution och eventuella påminnelser samt inkassokrav. Beloppet betalas ut inom kort.',
            Denied:
                'Denna faktura har inte överlåtits till Collector Bank. Ni sköter själva distribution, påminnelse och eventuellt inkasso.',
            Recourse: 'Denna faktura har överlåtits till Collector Bank med rätt till återköp.',
            Service:
                'Denna faktura har överlåtits till Collector Bank som sköter distribution och eventuella påminnelser och inkassokrav. Beloppet betalas ut så snart Er kund har betalat.',
        },
        getInvoiceCopy: 'Kopia',
        attachment: 'Bilaga',
        reminder: 'Påminnelse',
        getFiles: 'Hämta filer',
        missingFiles: 'Filer saknas',
        files: 'Filer',
        invoice: 'Faktura',
        clearSearch: 'Rensa',
    },
    registerAccountNumber: {
        chooseBankAccount: 'Vänligen välj Plus- eller Bankgiro för utbetalning',
        enterGiro: 'Vänligen mata in Plus- eller Bankgiro',
        enterManually: 'Jag vill ange kontonummer manuellt',
        chooseFromList: 'Nej, jag vill välja från lista',
        chooseAccount: 'Välj konto',
        chooseAccountType: 'Välj kontotyp',
        clearingNumber: 'Clearingnummer',
        accountNumber: 'Kontonummer (inkl. clearing)',
        accountInvalid: 'Validering misslyckades, vänligen kontrollera numret',
        accountCouldNotBeVerified: 'Kontonumret har rätt format men det kunde inte verifieras att det tillhör detta företag',
        documentDescription:
            'Vänligen bifoga bild eller dylikt som bevis på att ni är kontoinnehavare (t.ex. skärmklipp från er internetbank eller bankgirocentralen)',
        documentHeading: 'Filuppladdning',
        pageHeading: 'Utbetalningskonto',
        informationBox: 'Listan innehåller Bank- och Plusgironummer registrerade på ert organisationsnummer hos Bankgirocentralen',
    },
    registerBankAccount: 'Utbetalningskonto',
    companyLoansAccountType: {
        prefixes: {
            'SE-BA': 'Kontonummer',
            'SE-BG': 'Bankgiro',
            'SE-PG': 'Plusgiro',
            'NO-BA': 'Kontonummer',
            IBAN: 'IBAN',
        },
        label: 'Kontotyp',
    },
    from: 'Från',
    to: 'Till',
    invoiceDate: 'Fakturadatum',
    dueDate: 'Förfallodatum',
    recourseDate: 'Regressdatum',
    hideFilter: 'Dölj filter',
    showFilter: 'Visa filter',
    orderId: 'Fakturareferens',
    factAgreement: {
        document: {
            'Fakturaköpsavtal Collector Bank':
                'I fakturaköpsavtalet regleras priser och villkor för köp av fakturafordringar. Avtalet ingås mellan Ert bolag och Collector Bank AB. Observera att avtalet inte innehåller några fasta avgifter eller krav på nyttjande.',
            'Fakturakjøpsavtale Collector Bank': '',
            'Avtal inkassotjänster Colligent':
                'Då fakturaköpstjänsten omfattar hantering av förfallna fakturor av Colligent Inkasso AB behöver Ni signera ett separat avtal med Colligent Inkasso AB. Observera att avtalet inte innehåller några fasta avgifter eller krav på nyttjande.',
            'Avtale om inkassotjenster Colligent': '',
            'Kundeavtale 24SevenFinans AS for 24SevenOffice kunder': '',
            'Kundavtal 24SevenFinans AS':
                'I partneravtalet regleras affärsförhållandet mellan 24SevenFinans AS och Ert bolag. Genom att signera avtalet, vilket är ett krav för att använda tjänsten ger ni 24SevenFinans AS rätten att bland annat lagra och använda den erhållna informationen för att kunna erbjuda integration, samt för marknadsföring och förmedlan av andra produkter och tjänster.',
        },
    },
    users: 'Användare',
    address: {
        street: 'Adressrad 1',
        street2: 'Adressrad 2',
        postalCode: 'Postnummer',
        city: 'Stad',
        country: 'Land',
        careOf: 'c/o',
    },
    update: 'Uppdatera',
    rawAddress: 'Adress',
    factInvoice: {
        actions: {
            stopDebtCollectionAction: 'Stoppa inkasso',
            releaseDebtCollectionAction: 'Återuppta inkasso',
            changeDueDateAction: 'Ändra förfallodatum',
            registerDirectPayment: 'Registrera direktbetalning',
            title: 'Åtgärder',
        },
        stopDebtCollection: {
            header: 'Stoppa inkasso',
            confirmText: 'Är du säker på att du vill stoppa faktura {0} från inkasso?',
            commentFieldLabel: 'Kommentar (obligatoriskt)',
            confirmButton: 'Ja, stoppa faktura',
            cancelButton: 'Nej',
            stopped: 'Inkassostoppad',
            stoppRequested: 'Inkassostopp begärt',
            releaseRequested: 'Återuppta inkasso begärt',
            confirmationMessage: 'Begäran om att stoppa fakturan från inkasso är mottagen. ',
        },
        releaseDebtCollection: {
            header: 'Återuppta inkasso',
            confirmText: 'Är du säker på att du vill återuppta inkassoprocessen på faktura {0}?',
            confirmButton: 'Ja, återuppta inkassoprocessen',
            cancelButton: 'Nej',
            confirmationMessage: 'Begäran om att återuppta inkassoprocessen är mottagen.',
        },
        changeDueDate: {
            header: 'Ändra förfallodatum',
            confirmText: 'Välj nytt förfallodatum för faktura {0}.',
            confirmButton: 'Spara',
            cancelButton: 'Avbryt',
            confirmationMessage: 'Ansökan om flyttat förfallodatum är nu registrerad och väntar på godkännade',
            dueDate: 'Förfallodatum',
            pending: 'Förfallodatumflytt pågår',
        },
        registerDirectPayment: {
            header: 'Registrera direktbetalning',
            confirmText:
                'Är du säker på att du vill registrera direktbetalning på faktura {0}? Beloppet kommer att debiteras ditt avräkningskonto.',
            confirmButton: 'Ja, registrera direktbetalning',
            cancelButton: 'Avbryt',
            confirmationMessage: 'Ansökan om direktbetalning är nu registrerad och väntar på godkännande',
            paymentDate: 'Betalningsdatum',
            pending: 'Direktbetalning behandlas',
            error: 'Beloppet kan inte överstiga det återstående beloppet',
        },
        error: {
            errorHeader: 'Begäran misslyckades',
            errorText: 'Försök igen eller kontakta kundtjänst',
        },
        BlockFromDebtCollectionRequested: 'Begäran om att stoppa fakturan från inkasso',
        BlockedFromDebtCollection: 'Fakturan stoppad från inkasso',
        BlockFromDebtCollectionAborted: 'Begäran om att stoppa fakturan från inkasso avbruten',
        BlockFromDebtCollectionFailed: 'Misslyckades att stoppa fakturan från inkasso',
        ReleaseForDebtCollectionRequested: 'Begäran om att återuppta inkassoprocessen',
        ReleasedForDebtCollection: 'Fakturan återupptagen i inkassoprocessen',
        ReleaseForDebtCollectionAborted: 'Begäran om att återuppta inkassoprocessen misslyckades',
        ReleaseForDebtCollectionFailed: 'Begäran om att återuppta inkassoprocessen misslyckades',
        PostponeDueDateRequested: 'Begäran om att flytta fram förfallodatum',
        PostponeDueDateAborted: 'Begäran om att flytta fram förfallodatum misslyckades',
        PostponeDueDateFailed: 'Begäran om att flytta fram förfallodatum misslyckades',
        DueDatePostponed: 'Förfallodatum framflyttat',
        RegistrationOfDirectPaymentRequested: 'Begäran om att göra en direktbetalning',
        RegistrationOfDirectPaymentAborted: 'Direktbetalning misslyckades',
        RegistrationOfDirectPaymentFailed: 'Direktbetalning misslyckades',
        DirectPaymentRegistered: 'Direktbetalning på {0} registrerades ',
        changeStatus: { None: 'Inaktiv', ChangesInProgress: 'Behandlas' },
        statusTitle: {
            directPayment: 'Direktbetalning',
            postponeDuedate: 'Förfallodagsflytt',
        },
        directlyPaid: 'Direktbetald',
        event: 'Händelse',
        events: 'Händelser',
        missingEvents: 'Händelser saknas',
        backwards: 'Tillbaka till fakturor',
    },
    backwards: 'Tillbaka',
    agGrid: {
        invoiceStatus: {
            Open: 'Öppen',
            Closed: 'Stängd',
            Pending: 'Under behandling',
            Cancelled: 'Stoppad',
        },
        type: { invoice: 'Faktura', credit: 'Kreditnota' },
        invoiceTable: {
            status: 'Status',
            number: 'Nr',
            factoringDate: 'Fakturadatum',
            amount: 'Belopp',
            remainingAmount: 'Återstående',
            dueDate: 'Förfallodatum',
            pendingChanges: 'Pågående ändringar',
            overdue: 'Förfallen',
            organizationNumber: 'Org.nr',
            endCustomer: 'Slutkund',
            type: 'Typ',
            factoringType: 'Beslut',
            resetFilter: 'Återställ filter',
        },
        debtCollectionStatus: {
            InProgressBlock: 'Stoppar faktura',
            InProgressUnblock: 'Återupptar faktura',
        },
        postPoneStatus: { ChangesInProgress: 'Flyttar slutdatum' },
        directPayment: { ChangesInProgress: 'Direktbetalning registreras' },
        default: {
            selectAll: '(Välj samtliga)',
            selectAllSearchResults: '(Välj samtliga sökresultat)',
            searchOoo: 'Sök...',
            blanks: '(Tomt)',
            noMatches: 'Inga träffar',
            filterOoo: 'Filtrera...',
            equals: 'Lika med',
            notEqual: 'Inte lika med',
            empty: 'Välj en',
            lessThan: 'Mindre än',
            greaterThan: 'Större än',
            lessThanOrEqual: 'Mindre eller lika med',
            greaterThanOrEqual: 'Större eller lika med',
            inRange: 'Interval',
            inRangeStart: 'Till',
            inRangeEnd: 'Från',
            contains: 'Innehar',
            notContains: 'Innehar inte',
            startsWith: 'Börjar med',
            endsWith: 'Slutar med',
            dateFormatOoo: 'åååå-mm-dd',
            andCondition: 'OCH',
            orCondition: 'ELLER',
            applyFilter: 'Applicera',
            resetFilter: 'Återställ',
            clearFilter: 'Rensa',
            cancelFilter: 'Avbryt',
            textFilter: 'Textfilter',
            numberFilter: 'Nummerfilter',
            dateFilter: 'Datumfilter',
            setFilter: 'Valfilter',
            columns: 'Kolumner',
            filters: 'Filter',
            pivotMode: 'Gruppera',
            groups: 'Gruppera',
            rowGroupColumnsEmptyMessage: 'Drag hit för att sätta radgrupper',
            values: 'Värden',
            valueColumnsEmptyMessage: 'Drag hit för att aggregera',
            pivots: 'Kolumnetiketter',
            pivotColumnsEmptyMessage: 'Drag hit för att sätta kolumnetiketter',
            loadingOoo: 'Laddar...',
            noRowsToShow: 'Inga rader att visa',
            enabled: 'Aktiverad',
            pinColumn: 'Fastnåla kolumn',
            pinLeft: 'Fastnåla vänster',
            pinRight: 'Fastnåla höger',
            noPin: 'Ingen fastnålning',
            valueAggregation: 'Värdeaggregering',
            autoSizeThisColumn: 'Automatjustera storlek',
            autosizeThiscolumn: 'Automatjustera storlek',
            autosizeAllColumns: 'Automatjustera alla kolumner',
            groupBy: 'Gruppera efter',
            ungroupBy: 'AVgruppera efter',
            resetColumns: 'Återställ kolumner',
            expandAll: 'Expandera',
            collapseAll: 'Fäll in alla',
            copy: 'Kopiera',
            ctrlC: 'Ctrl+C',
            copyWithHeaders: 'Kopiera med rubriker',
            paste: 'Klistra',
            ctrlV: 'Ctrl+V',
            export: 'Exportera',
            csvExport: 'CSV-export',
            excelExport: 'Excel-export (.xlsx)',
            excelXmlExport: 'Excel-export (.xml)',
            sum: 'Summa',
            min: 'Min',
            max: 'Max',
            none: 'Inget',
            count: 'Antal',
            avg: 'Medel',
            filteredRows: 'Filtrerade',
            selectedRows: 'Valda',
            totalRows: 'Antal rader',
            totalAndFilteredRows: 'Rader',
            more: 'Mer',
            to: 'Till',
            of: 'av',
            page: 'Sida',
            nextPage: 'Nästa sida',
            lastPage: 'Sista sidan',
            firstPage: 'Första sidan',
            previousPage: 'Förra sidan',
            pivotChartAndPivotMode: 'Pivotgraf & pivot mode',
            pivotChart: 'Pivotgraf',
            chartRange: 'Diagramintervall',
            columnChart: 'Kolumndiagram',
            groupedColumn: 'Grupperad',
            stackedColumn: 'Staplad',
            normalizedColumn: '100% staplad',
            line: 'Linje',
            area: 'Area',
            scatter: 'Scatter',
            areaChart: 'Area',
            settings: 'Inställningar',
            categories: 'Categories',
            defaultCategory: '(Ingen)',
            series: 'Serie',
            xyValues: 'X Y Värden',
            paired: 'Parningsmode',
            axis: 'Axel',
            navigator: 'Navigator',
            color: 'Färg',
            thickness: 'Tjocklek',
            xTyp: 'X-typ',
            automatic: 'Automatisk',
            category: 'Kategori',
        },
        defaultNotTranslated: {
            barChart: 'Barchart',
            groupedBar: '',
            stackedBar: '',
            normalizedBar: '',
            pieChart: '',
            pie: '',
            doughnut: '',
        },
    },
    companySelection: { title: 'Välj företag' },
    browserNotSupported: '<b>Internet Explorer</b> stöds inte. Följande webbläsare stöds: <b>Edge, Chrome, Firefox and Safari',
};
