import config from '../config';
import swedish from './sv';
import norwegian from './nb';
import finnish from './fi';

let translations: typeof swedish & typeof norwegian;

switch (config.locale) {
    case 'sv':
        translations = swedish;
        break;
    case 'fi':
        translations = finnish;
        break;
    case 'nb':
    default:
        translations = norwegian;
        break;
}

export default translations;
