import { isValid as isValidNorwegianBirthNumber } from 'norwegian-birth-number-validator';
import { registerLocale, getNames as getCountryNames } from 'i18n-iso-countries';

registerLocale(require('i18n-iso-countries/langs/nb.json'));

export default {
    countryCode: 'no',
    locale: 'nb',
    currency: 'NOK',
    loginHint: 'nbid',
    signicatMethod: 'NorwegianBankId',
    companyLoansComplianceProductId: Number('#{complianceIdNO}#'),
    factoringComplianceProductId: 80,
    dateFormat: 'dd.MM.yyyy',
    personalIdentityNumberPlaceholder: 'DDMMÅÅNNNNN',
    personalIdentityNumberValidator: isValidNorwegianBirthNumber,
    months: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
    countries: getCountryNames('nb'),
};
