import React from 'react';
import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Spinner } from 'collector-portal-framework/dist/common/components/Spinner';
import styled from 'collector-portal-framework';
import translations from './translations';

const Login = lazy(() => import(/* webpackChunkName: 'login' */ './views/Login'));
const Customer = lazy(() => import(/* webpackChunkName: 'customer' */ './views/Customer'));

const BrowserContainer = styled.div({
    textAlign: 'center',
    fontSize: '1em',
    padding: '0.5em 0 1em 0',
    color: 'white',
    backgroundColor: 'red',
    width: '100%'
});

export const CorporatePortal: React.FC = () => {
    const isIE = () => {
        var ua = navigator.userAgent;
        var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1 || ua.indexOf("rv:xyz") > -1;

        return is_ie;
    };

    return (
        <Suspense fallback={<Spinner centered={true} />}>
            {isIE() && <BrowserContainer><div dangerouslySetInnerHTML={{ __html: translations.browserNotSupported }} /></BrowserContainer>}
            <Switch>
                <Route path="/" exact={true} component={Login} />
                <Route path="/customer" component={Customer} />
                <Route path="*" component={Login} />
            </Switch>
        </Suspense>
    );
};
