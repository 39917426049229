export default {
    authErrors: {
        correlation_failed: 'Innlogging mislyktes. Prøv igjen',
        invalid_request: 'Innlogging mislyktes. Prøv igjen. Merk at personnummer skal ha formatet DDMMÅÅ-NNNNN',
        session_expired: 'Du har blitt utlogget fordi du var inaktiv mer enn 15 minutter',
    },
    factoring: 'Fakturasalg',
    agreement: 'Avtale {0}',
    loanApplicationNumber: 'Søknadsnummer',
    applicant: 'Kredittakere',
    provisionalInterestRate: 'Veiledende rente',
    interestRate: 'Rente',
    guaranteeInterest: 'Garantiprovisjon',
    provisionalDuration: 'Preliminært bevilget løpetid',
    requestedDuration: 'Ønsket løpetid',
    grantedDuration: 'Bevilget løpetid',
    duration: 'Løpetid',
    processing: 'Under behandling',
    processingDescription: ' ',
    readyToSign: 'Klar til signering',
    readyToSignDescription: 'Söknaden er godkjent og klar til signering av signaturberettigede',
    waitingForCompletion: 'Venter på komplettering',
    supplementaryInfo: 'Komplettering',
    debenture: 'Gjeldsbrev',
    approved: 'Godkjent',
    closed: 'Stengt',
    applicationRejected: 'Søknad avslått',
    applicationRejectedDescription: 'Deres søknad er avslått. Kontakt kundeservice for mer informasjon',
    genericError: 'En feil oppstod',
    maintenance: 'På grunn av vedlikehold av systemet kan ikke informasjonen vises akkurat nå',
    cancel: 'Avbryt',
    completed: 'Ferdig',
    logIn: 'Logge inn',
    logInWithBankId: 'Logg inn med BankID',
    logInWithMobileBankId: 'Logg inn med BankID på mobil',
    logOut: 'Logge ut',
    thisWebSiteIsForCorporateCustomers: 'Denne siden er rettet mot bedriftskunder hos Norion Bank',
    generalCustomerServicePhoneNumber: '+46 10 - 161 00 00',
    corporateCustomerServicePhoneNumber: '+46 10 - 161 04 36',
    ifYouNeedPrivateHelpDescription: 'Om du har et spørsmål som gjelder deg som privatperson, vennligst ring',
    ifYouNeedCorporateHelpDescription: 'Hvis dere har spørsmål om eksisterende bedrifts- eller eiendomslån, vennligst kontakt oss på',
    orVisitUsAt: 'eller besøk oss på',
    collectorURL: 'http://collector.no',
    walleyURL: 'http://walley.no',
    customerServiceEmail: 'foretagslan@norionbank.se',
    startPage: 'Oversikt',
    doYouNeedHelp: 'Behøver du hjelp? Kontakt kundeservice på',
    or: 'eller',
    genericConfirmationQuestion: 'Er du sikker på at du vil ta bort?',
    genericConfirmationCancelQuestion: 'Er du sikker på at du vil avbryte? Det du har lagt inn vil forsvinne',
    add: 'Legg til',
    addEmail: 'Legg til email',
    save: 'Lagre',
    edit: 'Endre',
    remove: 'Ta bort',
    close: 'Steng',
    month: 'måned',
    months: 'måneder',
    personalIdentityNumber: 'Personnummer',
    invalidPersonalIdentityNumber: 'Personnummeret er ugyldig',
    nextStep: 'Lagre å gå vidare',
    nextStepWithoutSaving: 'Gå vidare',
    yes: 'Ja',
    no: 'Nei',
    miscellaneous: 'Allment',
    bank: 'Bank',
    amount: 'Beløp',
    currency: 'Valuta',
    documents: 'Dokument',
    verificationDocuments: 'Dokument for å bekrefte konto',
    attachments: 'Last opp fil',
    signing: 'Gå gjennom og signer',
    fortnox: '',
    signDebenture: 'Signer gjeldsbrev',
    signFactoringAgreement: 'Signer avtalene',
    readTheDebenture: 'Les gjeldsbrev',
    letter: 'Post',
    clCompleteApplicationFor: {
        creditType: {
            DevelopmentLoan: 'Fullfør lånesøknad for {0}'
        },
    },
    clCompleteApplication: {
        creditType: {
            DevelopmentLoan: 'Fullfør lånesøknad'
        },
        button: 'Fullfør søknad',
    },
    factApplicationFor: 'Søknad til fakturasalg for {0}',
    factApplication: 'Søknad til fakturasalg',
    dataProtectionDisclaimer:
        'I forbindelse med lånesøknad kommer vi til å behandle dine personopplysninger så lenge det finnes en rettslig grunn for dette. Det overgripende målet med behandlingen er å kontrollere og vurdere din søknad om å bli kunde og deretter håndtere og fullføre avtalen som er inngått. <a href="https://www.collector.no/om-collector/integritet-og-sikkerhet/retningslinjer-for-personvern/" target="_blank" rel="noopener noreferrer">Les mer om hvordan Collector håndterer dine personopplysninger.</a>',
    dataProtectionDisclaimerFactoring:
        'I forbindelse med fakturasalgsøknad kommer vi til å behandle dine personopplysninger så lenge det finnes en rettslig grunn for dette. Det overgripende målet med behandlingen er å kontrollere og vurdere din søknad om å bli kunde og deretter håndtere og fullføre avtalen som er inngått. <a href="https://www.collector.no/om-collector/integritet-og-sikkerhet/retningslinjer-for-personvern/" target="_blank" rel="noopener noreferrer">Les mer om hvordan Collector håndterer dine personopplysninger.</a>',
    companyType: 'Selskapsform',
    digitallySign: 'Signer med BankID',
    loan: 'Lån',
    noApplications: {
        DevelopmentLoan: 'Du har ingen lånesøknad registrert for tilfellet'
    },
    companyLoansUrl: {
        DevelopmentLoan: 'https://www.collector.no/bedrift/bedriftsfinansiering/'
    },
    readMore: {
        DevelopmentLoan: 'Les mer om Vekstlånet'
    },
    more: 'Mer',
    contactPerson: 'Kontaktperson',
    contactPersonDescription: 'Den person hos dere som Collector Bank kan henvende seg til om nødvendig.',
    companyAddress: 'Forretningsadresse',
    emailInvalid: 'Ugyldig e-post adresse',
    telephoneNumberInvalid: 'Nummeret er ikke gyldig. Angi +47xxxxxxxx eller +46xxxxxxxxx.',
    name: 'Navn',
    emailAddress: 'E-postadresse',
    emailAddresses: 'E-postadresser',
    email: 'E-mail',
    countryCode: 'Nasjonalitet',
    guarantors: 'Kausjonist',
    guarantorDescription: 'Å kausjonere for noen innebærer at man skyldig å betale om låntager ikke kan betale.',
    telephoneNumber: 'Mobiltelefonnummer',
    telephoneNumberIncludingCountryCode: '+46/+47 xx xxx xx xx',
    signatory: 'Signaturberettiget',
    guarantor: 'Kausjonist',
    companyLoansApproved: 'Nå er lånesøknaden din godkjent og klar for signering',
    factApproved: 'Deres fakturasalgsøknad er nå godkjent. For å ta i bruk tjenesten må nedenfor avtale være signert:',
    factSignTitle: 'Signer',
    factSign: 'Når du signerer denne søknaden samtykker du at informasjonen som er gitt er korrekt',
    factoringUrl: 'https://www.collector.no/bedrift/bedriftsfinansiering/factoring/',
    noCurrentApplications: 'Du har ingen aktive søknader under behandling.',
    factReadMore: 'Les mer om fakturasalg',
    welcome: 'Velkommen',
    factApplicationExists: 'Du har en søknad om fakturasalg',
    factMultipleApplications: 'Du har flere søknader om fakturasalg',
    goToApplications: 'Gå til søknader for å starte kompletteringen',
    goToApplication: 'Gå til søknad',
    bothApplications: 'Du har søknader både til fakturasalg og lån. Du finner dine søknader i hovedmenyen',
    companyLoansSignSuccessful: {
        supplementaryInfo: 'Vi vil nå vurdere din søknad! Vi kontakter deg når alt er klart.',
        deniedAfterSupplementaryInfoSigned:
            'Etter å ha gransket av det som ble komplettert av informasjon kan vi dessverre ikke tilby dere noe lån i dag. Ved en forbedret finansiell situasjon er dere velkommen til å søke på ny. Ved spørsmål, kontakt oss på telefon +46 10 161 00 00.',
        debenturePartlySigned: 'Gjeldsbrev er nå signert.',
        debentureFullySigned: 'Gjeldsbrev er nå signert.',
    },
    factoringApplicationSignSuccessful: 'Takk! Vi vil nå vurdere din søknad! Vi kommer tilbake snart med mer informasjon.',
    factoringTaskSignSuccessful: 'Takk! De avtalene du har signert vil bli sendt per e-post til deg innom kort.',
    signAborted: 'Signering ble avbrutt eller noe gikk galt',
    addApplicantToAdmin: {
        title: 'Administratorer\n',
        question: 'Ønsker du å legge til {0} som administrator i selskapet?',
        yes: 'Ja, legg til som administrator',
        no: 'Nei, gå videre til signering',
        finish: '{0} er nå lagt til som administrator og vil se alle aktive virksomhetsforpliktelser fremover',
        errorHeading: 'Kunne ikke legge til administrator\n',
        errorDescription: 'Vi kunne dessverre ikke legge til {0} som administrator. Ta kontakt med oss når du har fullført søknaden\n',
        moveToSigning: 'Gå videre til signering\n',
        description: 'En administrator vil kunne se og administrere hele selskapets involvering.',
    },
    clSummary: {
        textToSign: 'Herved bekrefter jeg at tilleggsinformasjon på søknaden {0} er korrekt',
        legalIntro:
            'Herved bekrefter jeg at informasjonen som er gitt er korrekt og at jeg har fullmakt til å sluttføre lånesøknad for {0}.',
        applicaitonCompleteInfo:
            'Etter fullført komplettering vil din søknad bli vurdert. Gjeldsbrev og allmenne vilkår må signeres av signarturberettigede for {0}.',
        accountNumber: 'Kontonummer',
    },
    fileUpload: {
        pleaseAttach: 'Vennligst legg ved følgende dokumenter:',
        incomeStatement: 'Aktuelt resultatregnskap',
        balanceSheet: 'Aktuelt balanse',
        uploadMultiple: 'For å laste opp flere filer, hold nede CTRL/CMD-knappen og marker alle filer.',
        notPasswordProtected: 'Dokumenter må ikke være passordbeskyttet.',
        validFileExtensions: 'Godkjente filformat: {0}.',
        maxFileSize: 'Max filstørrelse er 30 MB per fil',
        uploadFile: 'Last opp fil',
        pleaseConsider: 'Att tänka på...',
    },
    clQuestions: {
        new: 'Nystartet selskap',
        old: 'Eksisterende selskap',
        takeOver: 'Overtakelse av selskap',
        companyType: 'Selskapstype',
        loan: 'lån',
        existingLoans: 'Har selskapet for øyeblikket kreditt hos andre banker/långivere?',
        investingInPremises: 'Investering i lokaler',
        expandInventory: 'Økning av varelager',
        homepageMarketing: 'Hjemmeside/markedsføring',
        purchaseOfMachineryOrEquipment: 'Innkjøp av maskiner/utstyr',
        refinancingOfLoans: 'Refinansiering av lån',
        hireStaff: 'Ansette personell',
        liquidityForExpansion: 'Likviditet for ekspansjon',
        generallyIncreasedLiquidity: 'Generell økt likviditet',
        unexpectedExpenses: 'Uforutsette kostnader/utgifter',
        businessOverviewStatement: 'Virksomhetsbeskrivelse & forretningside',
        futurePlans: 'Fremtidsplaner',
        loanPurposes: 'Låneformål',
        numberOfEmployees: 'Antall ansatte',
        turnover: 'Omsettning de siste 12 måneder ({0})',
        profit: 'Resultat de siste 12 måneder ({0})',
    },
    factSummary: {
        textToSign: 'Herved bekrefter jeg at tilleggsinformasjon er korrekt',
        title: 'Komplettering',
    },
    factQuestions: {
        accountNumber: 'Hvilket kontonummer vil dere ha utbetalinger i {0} på?',
        businessDescription: 'Kort virksomhetsbeskrivelse',
        otherInformation: 'Øvrig informasjon',
        otherInformationDescription: 'Her kan du gi informasjon som ikke dekkes av våre spørsmål eller den kompletterende informasjonen.',
        shareOfBusinessCustomers: 'Hvor stor del av fakturering er mot bedriftsmarkedet?',
        averageDueDays: 'Hvor mange dager gir dere i gjennomsnitt i kredittid (antall dager)?',
        name: 'Navn',
        emailAddress: 'E-postadresse',
        telephoneNumber: 'Mobiltelefonnummer',
        address1: 'Adresse rad 1',
        address2: 'Adresse rad 2',
        zipCode: 'Postnummer',
        city: 'By',
        companyExisted: 'Har selskapet vært aktiv mer enn 12 måneder?',
        turnover: 'Hva forventer dere å omsette for i år? ({0})',
        requestedLimit: 'Ønsket kredittgrense? ({0}) (valgfritt)',
        requestedLimitDescription: 'Her angir dere hvor stor kredittgrense dere ønsker.',
        percent: 'ca {0} %',
        billInAdvance: 'Hender det at dere forskuddsfakturerer?',
        changeSellBillInAdvanceInvoices: 'Gjelder fakturaene dere vil selge til oss forskuddsfakturering?',
        billInAdvanceWarning:
            'Vær oppmerksom på at vi kun aksepterer fakturaer som er utstilt etter at hele arbeidet er utført / tjenesten er levert.',
        beforeDoneInvoice: 'Hender det at dere fakturerer før 100% av arbeidet er utført?',
        days: '{0} dager',
    },
    factFileUploader: {
        bankgiroScreenshot: {
            label: 'Kontobevis',
            description:
                'En konto utskrift hvor det viser at bankkonto tilhører deres selskap. Dette er nødvendig for å vite hvilket konto dere vil ha penger utbetalt til og at det er dere som eier kontoen.',
        },
        balanceSheetYearToDate: {
            label: 'Balanse så langt i år',
            description: 'En sammenstilling av årets balanse så langt. Vi trenger disse tallene for vurdere deres kredittverdighet.',
        },
        incomeStatementYearToDate: {
            label: 'Resultatregnskap så langt i år',
            description:
                'En sammenstilling av årets resultatregnskap så langt. Vi trenger disse tallene for vurdere deres kredittverdighet.',
        },
        annualReportLastYear: {
            label: 'Årsregnskap for fjoråret',
            description:
                'En sammenstilling av fjorårets resultat & balanse. Vi trenger disse tallene for vurdere deres finasielle kredittverdighet.',
        },
        confirmationOfPaidCapital: {
            label: 'Bekräftelse på inbetalt kapital',
            description:
                'En bekreftelse fra deres bank at selskapet har betalt inn aksjekapital. Vi har som krav at aksjekapital skal være innbetalt og bekreftet av deres bank.',
        },
        proofOfOwnership: {
            label: 'Firmaattest',
            description: 'Firma attest fra Brønnøysundsregisteret. Firma attesten trenger vi for å se at deres selskap er registert.',
        },
    },
    timeOutWarning: {
        heading: 'Du holder på å bli logget ut',
        body: 'Du blir logget ut på grunn av inaktivitet. Vil du fortsette å være innlogget?',
        confirm: 'Ja takk, hold med innlogget',
        discard: 'Nei takk',
    },
    timeOutWarningSessionMaxTime: {
        body: 'Om 15 minutter vil du bli logget ut, logg ut og logg på igjen for å starte en ny økt.',
        confirm: 'Ok',
        discard: 'Logg ut',
    },
    sign: 'Signer',
    isSigning: 'Under signering',
    alreadySigned: 'Signert',
    signatureComplete: 'Takk!',
    signatureCompleteButDenied: 'Vi har ikke mulighet til å tilby noe lån til dere i dag',
    debentureComplete: 'Takk!',
    agreementSignatureComplete: 'Takk for din signering!',
    optional: 'valgfritt',
    status: 'Status',
    detailedInfo: 'Detaljert informasjon',
    completeLoanApplication: 'Ferdigstill lånesøknad',
    completeDigitalFactoring: 'Ferdigstill fakturasalg',
    completeDigitalFactoringApplication: 'Ferdigstill fakturasalg',
    moveAlong: 'Gå videre',
    previous: 'Forrige',
    generalInfo: 'Generell informasjon',
    complianceQuestions: 'Tilleggsspørsmål',
    reviewAndComplete: 'Signer søknaden',
    almostFinished: {
        creditType: {
            DevelopmentLoan: 'Klikk fullfør søknad for å komplementere med den siste informasjonen.'
        },
        description: 'Klikk fullfør søknad for å komplementere med den siste informasjonen.',
    },
    almostFinishedFactoring: 'Klikk fullfør søknad for å komplementere med den siste informasjonen.',
    requestedLoanAmount: 'Ønsket kredittbeløp',
    grantedLoanAmount: 'Beviljet kredittbeløp',
    provisionalGrantedLoanAmount: 'Preliminært bevilget beløp',
    companyName: 'Selskapsnavn',
    organisationNumber: 'Organisasjonsnummer',
    type: 'Typ',
    signatoryCombinations: {
        signatories: 'Signaturrettshavere',
        choiceOfSignatories: 'Valg av signaturrettshaver',
        chooseSignatoriesPlaceholder: 'Velg signaturrettshaver',
        chooseConstellation: 'Velg hvem av signaturrettshavere som skal signere avtalen.',
        contactInfoHeading: 'Kontaktinformasjon for {0}',
        noCombinationsFound: 'Vi kunne ikke identifisere som er signaturrettshavere i deres selskap. Vær vennlig å ring kundeservice.',
        procuration: 'Prokura',
    },
    loanApplications: 'Lånesøknader',
    applications: 'Søknader',
    adviceMethod: 'Faktureringsmetode',
    companyLoanDetails: {
        companyDetails: {
            title: 'Selskapsdetaljer',
            contactPerson: 'Kontaktperson til selskapet',
            choseAdviceMethod: 'Velg faktureringsmetode',
            adviceEmail: 'E-postadresse ved fakturering\n',
        },
        invoices: 'Fakturaer',
        denyOthers: {
            title: 'flere pågående søknader',
            cancel: 'nei, avbryt',
            description:
                'Vi legger merke til at du har flere pågående søknader. Hvis du valgte å fortsette med denne applikasjonen, blir de andre automatisk lukket. Vil du fortsette å signere med denne søknaden?\n',
            descriptionWithCompany:
                'Vi noterar att du har flera pågående ansökningar just nu. Väljer du att gå vidare med den här ansökan, kommer resterande ansökningar att automatiskt stängas. Vill du fortsätta till signering med den här ansökan från {0}?',
            approve: 'Ja, fortsett å signere',
        },
        upcoming: 'Kommende',
        notified: 'Sendte',
        ocrNumber: 'KID nummer',
        elapsedAmount: 'Forfalt beløp',
        capitalDebt: 'Hovedstol',
        originalAmount: 'Kapitalbeløp',
        guaranteeAmount: 'Garantibeløp',
        currentDebt: 'Lånesaldo',
        registrationNumber: 'Lånenummer',
        facilityFee: 'Oppstartsavgift',
        creditNumber: 'Avtalenummer',
        signedDate: 'Avtaledato',
        endDate: 'Innfrielse',
        appliedThrough: 'Påført',
        overdueInvoicesHeading: 'Forfalt faktura',
        overdueInvoicesMessage: 'Du har et forfalt beløp på {0}. Betal inn det forfalte beløpet omgående for å unngå ytterligere avgifter.',
        invoicingInfo: 'Fakturering',
        invoicingMethod: 'Faktureringsmetode',
        invoicingRecipients: 'Faktureringsmottakere',
        invoicingUpdateFailed: 'Mislyktes i å oppdatere faktureringsmetode\n',
        invoicingWarning: 'Merk! Den valgte faktureringsmetoden refererer bare til fakturaer vedrørende lån / kreditter.',
        changeInvoicingMethod: 'Endre faktureringsmetode',
        loanStatuses: {
            Created: 'Registrert',
            Activated: 'Aktiv',
            Terminated: 'Avsluttet',
            MarkedForDebtCollection: 'Inkassomarkert',
            PendingTermination: 'Oppsagt',
        },
        capitalOverview: {
            show: 'Vis kapitaloversikt',
            title: 'Kapitaloversikt',
            stopShow: 'Skjul kapitaloversikt',
            Amortisation: 'Avdrag',
            Disbursement: 'Utbetaling',
            CapitalizedInterest: 'Balanseførte renter',
            CapitalizedFacilityFee: 'Kapitalisert fasilitetsavgift',
            debt: 'Hovedstol',
        },
        invoicePaymentStatuses: {
            Unpaid: 'Ubetalt',
            PartlyPaid: 'Delbetalt',
            Paid: 'Betalt',
            Credited: 'Kreditert',
        },
        invoiceStatuses: {
            NotNotified: 'Ikke fakturert',
            Notified: 'Fakturert',
            Overdue: 'Forfalt',
            ReminderSent: 'Påminnelse sendt',
        },
        invoiceCopy: {
            buttonText: 'Send avi på nytt',
            title: 'Send avi på nytt',
            send: 'Send avi på nytt',
            failed: 'Mislyktes ved å sende avi\n',
            success: 'Anmodning om å sende faktura på nytt er godkjent',
            successDetails: 'Vi vil sende den valgte faktura på nytt. Det kan ta opp til en time før du ser den i innboksen',
            maximalAmountReached: 'Maks 2 eksemplarer per dag\n',
        },
        isDue: 'Forfaller',
        leftToPay: 'Gjenstående å betale:',
        invoiceDetails: { description: 'Beskrivelse', type: 'Type', amount: 'Beløp' },
        itemType: {
            HandlingFee: 'Administrasjonsgebyr',
            ArrangementFee: 'Etableringsgebyr',
            PrepaymentFee: 'Avgift førtidig innfrielse',
            OtherFee: 'Annen avgift',
            FacilityFee: 'Limitavgift',
            ReminderFee: 'Purregebyr',
            Expense: 'Utlegg',
            Installment: 'Avdrag',
            Interest: 'Rente',
            PenaltyInterest: 'Morarente',
            Rounding: 'Øresavrunding',
            Disbursement: 'Utbetaling',
            Payment: 'Innbetaling',
            Notification: 'Faktura gebyr',
            Aconto: 'A konto',
            GuaranteeInterest: 'Garantiprovisjon',
        },
        creditType: {
            Company: 'Bedriftslån',
            Property: 'Eiendomslån',
            EntryLoan: 'Lån',
            EmergencyLoan: 'Lån',
            DevelopmentLoan: 'Vekstlån',
            ECommerceLoan: 'Lån',
            LeaseLoan: 'Lån',
            PropertyPrivate: 'Lån',
            PrivateCredit: 'Lån',
            EmployeeCredit: 'Lån',
            BankGuarantee: 'Bankgaranti',
            BankGuaranteeProperty: "Bankgaranti (Eiendom)"
        },
        creditStatus: { Draft: 'Utkast', Active: 'Aktivt', Closed: 'Avsluttet' },
        noNotifiedInvoices: 'Dere har ingen sendte fakturaer',
        noNotNotifiedInvoices: 'Dere har ingen kommende fakturaer',
        transactionTab: {
            history: 'Historie',
            withdrawal: 'Uttak',
            stopWithdrawal: 'Stoppe utbetaling',
        },
    },
    clTransactions: { noTransactions: 'Det er ingen transaksjoner i dette datoperioden' },
    clTransactionReport: {
        title: 'Last ned liste med transaksjoner',
        downloadText: 'Last ned som fil',
        csv: { date: 'Dato', type: 'Type', amount: 'Beløp', currency: 'Valuta' },
        type: { Payment: 'Innbetaling', Disbursement: 'Utbetaling' },
    },
    stopWithdrawal: {
        heading: 'Stoppe utbetaling',
        question: 'Er du sikker på at du vil stoppe utbetalingen?',
        confirm: 'Ja, stopp utbetalingen',
        discard: 'nei, avbryt',
        toolTip: {
            CancellationInitiated: 'Forsøk på å stoppe utbetalingen startet',
            CancellationFailed: 'Mislyktes å stoppe utbetaling',
        },
        done: 'Klar',
        info:
            'Forespørsel om å stoppe utbetalingen er nå påbegynt. Vi kommer til å forsøke å stoppe så snart som mulig. Du kan se utviklingen under pågående transaksjoner.',
        error: 'Det var ikke mulig å avbryte utbetalingen for tilfellet.',
        errorHeading: 'Stopp av utbetalingen mislyktes',
    },
    withdrawalQuestions: {
        dateSelect: {
            asap: 'Innstendig',
            other: 'Annen dato',
            future: 'Fremtidig dato',
        },
        date: { label: 'Dato for uttak' },
        withDrawalAmount: 'Beløp',
        toAccount: 'til konto',
        submit: 'Bekrefte utmelding',
        noAccount: 'Dessverre er ingen bankkonto koblet til kreditten. Kontakt kundeservice for mer informasjon.',
        confirm: 'Godkjenn',
        cancel: 'Avbryte uttak',
        confirmQuestion: 'Gjennomgå og godkjenne\n',
        completed: {
            approved: 'Overføringen er godkjent\r',
            receipt: 'Kvittering\n',
            done: 'Klar',
        },
        error: { denied: 'Utbetalingen mislyktes' },
        disabled: 'Uttak er for øyeblikket deaktivert. Kontakt kundeservice for mer informasjon.',
    },
    disbursementStatus: {
        Initiated: 'Initiert',
        CancellationFailed: 'Initiert',
        CancellationInitiated: 'Initiert',
        Completed: 'Utbetalt',
        Cancelled: 'Stoppet',
        Denied: 'Benektet',
        Failed: 'Mislyktes',
    },
    documentsView: {
        noDocuments: 'Du har ingen dokument for øyeblikket.',
        engagementReportsHeader: 'Engasjement spesifikasjon',
        noEngagements: 'Ingen engasjement spesifikasjon knyttet til selskapet',
        download: 'Laste ned',
        companySelect: 'Velg bedrift',
        selectToDate: 'Engasjement t.o.m.',
        includeTerminated: 'Inkludert terminert lån',
        agreements: 'Avtaler',
        selectFromDate: 'Engasjement  f.o.m.',
        includeFromDate: 'Spesifiser datointervall',
        language: 'Språk',
        swedish: 'Svensk',
        norwegian: 'Norsk',
        finnish: 'Finsk',
        english: 'Engelsk',
    },
    userView: {
        noActiveCompanies: 'Du har for øyeblikket ingen aktive forpliktelser til å administrere',
        pageHeader: 'Brukere',
        heading: 'Administrere brukere',
        companyHeading: 'Administrere brukere for {0}',
        role: { Signatory: 'Signaturberettiget', Admin: 'Administrator' },
        addUser: 'Legg til bruker.',
        duplicateUser: 'Brukere finnes allrede',
        confirmation: 'Ja takk, ta bort',
        userConfirmationQuestion: 'Er du sikker på at du vil ta bort denne brukeren?',
        telephoneNumberInvalid: 'Angi format +47 xx xx xx xx for norske brukere og +46 xxx xx xx xx for svenske brukere.',
    },
    fortnoxApiKey: {
        apiKey: '',
        connect: '',
        approvedHeader: '',
        approved: '',
        information: '',
        informationLink: '',
        error: '',
        apikeyError: '',
        pleaseWait: '',
    },
    loading: 'Laster...',
    sieUploader: {
        instructions: '',
        manualUpload: '',
        cancelManual: '',
        heading: '',
        success: '',
        errorParsingSieFile: '',
        tooOldIncomeStatement: '',
    },
    saftUploader: {
        instructions: 'For å fullføre vurderingen behøver vi selskapets balanse og resultatregnskap i form av en SAF-T\n',
        manualUpload: 'Fortsett uten å last opp SAF-T-fil',
        cancelManual: 'Last opp SAFT-T-fil i stedet',
        heading: 'Last opp balanse og resultatregnskap',
        success: 'Balanse og resultatregnskap lastet opp!',
        errorParsingSaftFile: 'Noe gikk galt. Forsikre deg om at du lastet opp riktig SAFT-fil.',
        tooOldIncomeStatement: 'Resultatregnskapet må omfatte perioder fra inneværende eller forrige år.',
    },
    fileUploader: {
        error: {
            tooOldIncomeStatement: 'Resultatregnskapet må omfatte perioder fra inneværende eller forrige år.',
            errorParsingSaftFile: 'Noe gikk galt. Forsikre deg om at du lastet opp riktig SAFT-fil.',
            errorParsingSieFile: '',
            failedToRemove: 'Noe gikk galt.',
        },
    },
    companyLoanTransactionType: { Disbursement: 'Utbetaling', Payment: 'Innbetaling' },
    transactions: 'Transaksjoner',
    factClient: {
        client: 'Fakturakjøpsavtale',
        clientSummary: 'Oversikt',
        limit: { unusedLimit: 'Tilgjengelig limit', usedLimit: 'Benyttet limit' },
        summary: {
            totalLimit: 'Total limit',
            debtAmount: 'Saldo avregningskonto',
            openCreditnotes: 'Åpne kreditfakturaer',
            openCreditnotesDescription: 'Kreditnota som er mottatt  ',
            myInvoices: 'Mine fakturaer',
            invoiceStatus: 'Fakturastatus',
        },
        factoringType: {
            buyAmount: 'Solgt',
            recourseAmount: 'Solgt med regress',
            recoursedAmount: 'Tilbakekjøpt',
            serviceAmount: 'Service',
            Buy: 'Finansiert',
            Denied: 'Ikke finansiert',
            Recourse: 'Finansiert med regress',
            Service: 'Administrasjon',
        },
        invoiceStatus: {
            openAmount: 'Ikke forfalte',
            expiredInvoicesAmount: 'Forfalte',
            expiredInvoicesWithRemindersSentAmount: 'Påminnelse',
            debtCollectionAmount: 'Inkassomarkert',
            open: 'Åpen',
            closed: 'Stengt',
        },
        creditnote: 'Kreditfaktura',
        invoices: 'Fakturaer',
        invoiceDetails: {
            description: 'Beskrivelse',
            itemNumber: 'Artikkelnummer',
            amount: 'Beløp',
        },
        transactionCodes: {
            '1000': 'Transaksjon',
            '1001': 'Administrasjonsavgift kreditfaktura',
            '1002': 'Kreditfaktura bokført',
            '1003': 'Betaling koblet til tilbakekjøpt faktura eller servicefaktura',
            '1004': 'Betaling av servicefaktura',
            '1005': 'Finansierte fakturaer',
            '1006': 'Tilbakekjøp av faktura',
            '1007': 'Regressrente belastet',
            '1008': 'Finansiering avbrutt',
            '1009': 'Direktebetaling registrert',
            '1010': 'Utbetaling',
            '1011': 'Årsavgift',
            '1012': 'Betaling av tilbakekjøpt faktura',
            '1013': 'Administrasjonsavgift',
            '1014': 'Provisjon',
            '1015': '',
            '1016': '',
        },
        transactionList: 'Transaksjoner',
        transactionDetails: {
            reference: 'Referanse',
            information: 'Informasjon',
            transactionType: 'Transaksjonstype',
            currencyCode: 'Valuta',
            postingDate: 'Datum',
            transactionCode: 'Transaktionskod',
            amount: 'Belopp',
            downloadCsv: 'Ladda ner som fil',
        },
        noSoldInvoices: 'Her var det tomt! Her kommer dine fakturaer til å vises.',
        noTransactions: 'Her var det tomt! Her kommer dine transaksjoner til å vises.',
        invoiceReasonCode: {
            '1001': 'Faktura er mer enn 10 dager gammel.',
            '1002': 'Faktura har dato fram i tid eller ikke korrekt fakturadato.',
            '1003': 'Faktura kan ikke finansieres pga for høy kreditt risiko',
            '1004': 'Faktura kan ikke finansieres er insolvent',
            '1005': 'faktura kan ikke finansieres da det ikke er plass innenfor finansieringlimiten',
            '1006': 'Faktura kan ikke finansieres med regress da det ikke er plass innenfor regresslimiten',
            '1007': 'Faktura kan ikke finansieres pga den totale eksponeringen mot sluttkunden',
            '1008': 'Styremedlem er aktiv både hos klient og sluttkunden',
            '1009': 'Kunden befinner seg i ett segment som er sperret for finansiering for tilfellet',
            '2009': 'Betalingsvilkår overstiger 90 dager',
            heading: 'Årsak til finansieringsbeslutning.',
        },
        transactions: {
            searchTransactions: 'Søk på referanse eller beløp',
            filterTransactionType: 'Filtrere blant transaksjonstyper',
        },
        status: {
            Active: 'Aktiv',
            Blocked: 'Blokkert',
            Terminated: 'Oppsagt',
            blockedInfo: 'Avtalen er blokkert og fakturakjøptjenesten er derfor ikke aktiv',
            terminatedInfo: 'Avtalen er oppsagt og er ikke aktiv.',
        },
        invoiceSearchAndFilter: {
            invoiceStatusFilterLabel: 'Status',
            invoiceTypeFilterLabel: 'Finansieringsbeslutning',
            searchLabel: 'Søk på fakturanummer eller kunde',
        },
        sortHeader: 'Sorteringsordning',
        sortByInvoiceDateDesc: 'Fakturadato fallende',
        sortByInvoiceDateAsc: 'Fakturadato stigende',
        sortByDueDateDesc: 'Forfallsdato fallende',
        sortByDueDateAsc: 'Forfallsdato stigende',
        factoringTypeText: {
            Buy:
                'Denne faktura er overlatt til Collector Bank som distribuerer og håndterer påminnelser og evt inkasso. Kommer til utbetaling innom kort.',
            Denied: 'Denne faktura er ikke overlatt til Collector Bank. Dere sørger selv for utsendelse, påminnelse og evt inkasso.',
            Recourse: 'Denne faktura er overlatt til Collector Bank med rett til tilbakekjøp.',
            Service:
                'Denne faktura er overlatt til Collector Bank som distribuerer og håndterer påminnelser og evt inkasso. Beløpet utbetales til dere så snart som deres kunde har betalt.',
        },
        getInvoiceCopy: 'Kopi',
        attachment: 'Vedlegg',
        reminder: 'Påminnelse',
        getFiles: 'Hent filer',
        missingFiles: 'Filer savnes',
        files: 'Filer',
        invoice: 'Faktura',
        clearSearch: 'Slette',
    },
    registerAccountNumber: {
        chooseBankAccount: '',
        enterGiro: '',
        enterManually: '',
        chooseFromList: '',
        chooseAccount: '',
        chooseAccountType: '',
        clearingNumber: '',
        accountNumber: 'Kontonummer',
        accountInvalid: 'Validering mislyktes, vennligst kontroller kontonummer',
        accountCouldNotBeVerified: 'Kontonummeret er i riktig format, men det kunne ikke bekreftes at det tilhører dette selskapet',
        documentDescription:
            'Vennligst legg ved bilde eller lignende som viser at du er kontoeier. (F.eks. skjermdump fra Internett banken).',
        documentHeading: 'Last opp fil',
        pageHeading: 'Utbetalingskonto',
        informationBox: '',
    },
    registerBankAccount: 'Utbetalingskonto',
    companyLoansAccountType: {
        prefixes: {
            'SE-BA': '',
            'SE-BG': '',
            'SE-PG': '',
            'NO-BA': 'Kontonummer',
            IBAN: 'IBAN',
        },
        label: 'Konto',
    },
    from: 'Fra',
    to: 'Til',
    invoiceDate: 'Fakturadato',
    dueDate: 'Forfallsdato',
    recourseDate: 'Regressdato',
    hideFilter: 'Skjul filter',
    showFilter: 'Vise filter',
    orderId: 'Fakturareferanse',
    factAgreement: {
        document: {
            'Fakturaköpsavtal Collector Bank': '',
            'Fakturakjøpsavtale Collector Bank':
                'I fakturakjøpsavtalen reguleres priser og vilkår for kjøp av fakturaer. Avtalen inngås mellom deres selskap og Collector Bank AB. Vær observant på at avtalen ikke inneholder noen faste avgifter eller krav om bruk.',
            'Avtal inkassotjänster Colligent': '',
            'Avtale om inkassotjenster Colligent':
                'Da forfalte fakturaer håndteres av Colligent Inkasso AB, er det nødvendig at det signeres en egen avtale med Colligent Inkasso AB. Vær observant på at avtalen ikke inneholder noen faste avgifter eller krav om bruk.',
            'Kundeavtale 24SevenFinans AS for 24SevenOffice kunder':
                'Partneravtalen regulerer forholdet mellom 24SevenFinans og ditt selskap. Avtalen må signeres for å benytte seg av tjenesten, den gir blant annet 24SevenFinans rett til å lagre og bruke de innhentede opplysninger for å kunne tilby integrasjonen i 24SevenOffice, samt til markedsføring og formidling av andre produkter og tjenester.',
            'Kundavtal 24SevenFinans AS': '',
        },
    },
    users: 'Brukere',
    address: {
        street: 'Adresse rad 1',
        street2: 'Adresse rad 2',
        postalCode: 'Postnummer',
        city: 'By',
        country: 'Land',
        careOf: 'c/o',
    },
    update: 'oppdater',
    rawAddress: 'Adresse',
    factInvoice: {
        actions: {
            stopDebtCollectionAction: 'Inkassostopp',
            releaseDebtCollectionAction: 'Gjenoppta inkasso',
            changeDueDateAction: 'Endre forfallsdato',
            registerDirectPayment: 'Registrere direktebetaling',
            title: 'Handlinger',
        },
        stopDebtCollection: {
            header: 'Inkassostopp',
            confirmText: 'Er du sikker på at du vil stoppe faktura {0} fra inkasso.',
            commentFieldLabel: 'Kommentar (obligatorisk)',
            confirmButton: 'Ja, stopp faktura',
            cancelButton: 'Nei',
            stopped: 'Inkassostoppet',
            stoppRequested: 'Forespurt inkassostopp',
            releaseRequested: 'Gjenoppta inkasso forespurt',
            confirmationMessage: 'Forespurt om å stoppe faktura fra inkasso',
        },
        releaseDebtCollection: {
            header: 'Gjenoppta inkasso',
            confirmText: 'Er du sikker på at du vil gjenoppta inkassoprosess på faktura {0}?',
            confirmButton: 'Ja, gjenoppta inkassoprosessen',
            cancelButton: 'Nei',
            confirmationMessage: 'Forespurt ønske om å gjenoppta inkassoprosess mottatt.',
        },
        changeDueDate: {
            header: 'Endre forfallsdato',
            confirmText: 'Velg nytt forfallsdato for faktura {0}.',
            confirmButton: 'Spare',
            cancelButton: 'Avbryt',
            confirmationMessage: 'Søknad om å flytte forfallsdato er nå registrert og venter på godkjenning.',
            dueDate: 'Forfallsdato',
            pending: 'Forfallsdato flytting i prosess',
        },
        registerDirectPayment: {
            header: 'Registrere direktebetaling.',
            confirmText:
                'Er du sikker på at du vil registrer direktebetaling på faktura {0}. Beløpet vil bli belastet ditt avregningskonto.',
            confirmButton: 'Ja, registrer direktebetaling.',
            cancelButton: 'Avbryt',
            confirmationMessage: 'Søknaden angående direktebetaling er nå registrert og venter på godkjenning.',
            paymentDate: 'Betalingsdato',
            pending: 'Direkte betaling blir behandlet',
            error: 'Beløpet kan ikke overstige restbeløpet.',
        },
        error: {
            errorHeader: 'Forespørsel mislyktes.',
            errorText: 'Forsøk igjen eller kontakt kundesupport.',
        },
        BlockFromDebtCollectionRequested: 'Forespørsel om å stoppe faktura fra inkasso',
        BlockedFromDebtCollection: 'Faktura stoppet fra inkasso.',
        BlockFromDebtCollectionAborted: 'Forespørsel om å stoppe faktura fra inkasso avbrutt.',
        BlockFromDebtCollectionFailed: 'Mislyktes å stoppe faktura fra inkasso',
        ReleaseForDebtCollectionRequested: 'Forespørsel om å gjenoppta inkassoprosessen.',
        ReleasedForDebtCollection: 'Faktura sendt til inkasso igjen',
        ReleaseForDebtCollectionAborted: 'Forespørsel om å gjenoppta inkassoprosess mislyktes',
        ReleaseForDebtCollectionFailed: 'Forespørsel om å gjenoppta inkassoprosess mislyktes',
        PostponeDueDateRequested: 'Forspørsel om å flytte forfallsdato',
        PostponeDueDateAborted: 'Forspørsel om å flytte forfallsdato mislyktes',
        PostponeDueDateFailed: 'Forspørsel om å flytte forfallsdato mislyktes',
        DueDatePostponed: 'Forfallsdato framflyttet',
        RegistrationOfDirectPaymentRequested: 'Forespørsel om å gjøre en direktebetaling',
        RegistrationOfDirectPaymentAborted: 'Direkte betaling mislyktes',
        RegistrationOfDirectPaymentFailed: 'Direkte betaling mislyktes',
        DirectPaymentRegistered: 'Direkte betaling registrert',
        changeStatus: { None: 'Inaktiv', ChangesInProgress: 'Blir behandlet' },
        statusTitle: {
            directPayment: 'Direkte betaling',
            postponeDuedate: 'Forfallsdato flytting',
        },
        directlyPaid: 'Direktebetalt',
        event: 'Hendelse',
        events: 'Hendelser',
        missingEvents: 'Hendelser savnes',
        backwards: 'Tilbake til fakturaer',
    },
    backwards: 'Tilbake',
    agGrid: {
        invoiceStatus: {
            Open: 'Åpen',
            Closed: 'Stengt',
            Pending: 'I påvente av behandling',
            Cancelled: 'Stoppet',
        },
        type: { invoice: 'Faktura', credit: 'Kreditfaktura' },
        invoiceTable: {
            status: 'Status',
            number: 'Nr',
            factoringDate: 'Fakturadato',
            amount: 'Beløp',
            remainingAmount: 'Resterende beløp',
            dueDate: 'Forfallsdato',
            pendingChanges: 'Ventende endringer',
            overdue: 'Forfalt',
            organizationNumber: 'Org.nr',
            endCustomer: 'Sluttkund',
            type: 'Type',
            factoringType: 'Beslutning',
            resetFilter: 'Tilbakestill filter',
        },
        debtCollectionStatus: { InProgressBlock: '', InProgressUnblock: '' },
        postPoneStatus: { ChangesInProgress: '' },
        directPayment: { ChangesInProgress: '' },
        default: {
            selectAll: '(Velg alle)',
            selectAllSearchResults: '(Velg alle søkeresultatene)',
            searchOoo: 'Søk...',
            blanks: '(Plott)',
            noMatches: 'Ingen treff',
            filterOoo: 'Filter...',
            equals: 'Lik',
            notEqual: 'Ikke lik',
            empty: 'velg en',
            lessThan: 'Mindre enn',
            greaterThan: 'Større enn',
            lessThanOrEqual: 'Mindre eller lik',
            greaterThanOrEqual: 'Større eller lik',
            inRange: 'Intervall.',
            inRangeStart: 'Til',
            inRangeEnd: 'Fra',
            contains: 'Besitter',
            notContains: 'Holder ikke',
            startsWith: 'Starter med',
            endsWith: 'Slutter med',
            dateFormatOoo: 'yyyy-mm-dd',
            andCondition: 'OG',
            orCondition: 'ELLER',
            applyFilter: 'Søke om',
            resetFilter: 'nullstille',
            clearFilter: 'Klar',
            cancelFilter: 'Avbryt',
            textFilter: 'Tekstfilter.',
            numberFilter: 'Nummerfilter.',
            dateFilter: 'Datofilter.',
            setFilter: 'Valfilter.',
            columns: 'Kolonner',
            filters: 'Filter',
            pivotMode: 'Gruppe',
            groups: 'Gruppe',
            rowGroupColumnsEmptyMessage: 'Dra her for å sette linjegrupper',
            values: 'Verdier',
            valueColumnsEmptyMessage: 'Dra her for å aggregere',
            pivots: 'Kolonne etiketter',
            pivotColumnsEmptyMessage: 'Dra her for å sette Colum-etiketter',
            loadingOoo: 'Laster ...',
            noRowsToShow: 'Ingen rader å vise',
            enabled: 'Aktivert',
            pinColumn: 'Fain kolonne',
            pinLeft: 'Fain Left.',
            pinRight: 'Endinal akkurat nå',
            noPin: 'Ikke sittende fast',
            valueAggregation: 'Verdi aggregering.',
            autoSizeThisColumn: 'Automatjusteringsstørrelse.',
            autosizeThiscolumn: 'Automatjusteringsstørrelse.',
            autosizeAllColumns: 'Automatisk juster alle kolonnene',
            groupBy: 'Gruppe etter',
            ungroupBy: 'Ignoup etter',
            resetColumns: 'Tilbakestill kolonner.',
            expandAll: 'Utvide',
            collapseAll: 'Fold i det hele tatt',
            copy: 'Kopiere',
            ctrlC: 'CTRL + C.',
            copyWithHeaders: 'Kopier med rubrikker',
            paste: 'Lim inn',
            ctrlV: 'CTRL + V.',
            export: 'Eksport',
            csvExport: 'CSV eksport',
            excelExport: 'Excel eksport (.xlsx)',
            excelXmlExport: 'Excel eksport (.xml)',
            sum: 'Beløp',
            min: 'Min',
            max: 'Maks.',
            none: 'Ingenting',
            count: 'Mengde',
            avg: 'Gjennomsnitt',
            filteredRows: 'Filtrert',
            selectedRows: 'Valgt',
            totalRows: 'Antall rader',
            totalAndFilteredRows: 'Linjer',
            more: 'Mer',
            to: 'Til',
            of: 'av',
            page: 'Side',
            nextPage: 'Neste side',
            lastPage: 'Siste side',
            firstPage: 'Første side',
            previousPage: 'Forrige side',
            pivotChartAndPivotMode: 'Pivot Graf og Pivot-modus',
            pivotChart: 'Pivot graf',
            chartRange: 'Diagramintervall.',
            columnChart: 'Kolonne diagrammer.',
            groupedColumn: 'Gruppert',
            stackedColumn: 'Stablet.',
            normalizedColumn: '100% stablet.',
            line: 'Linje',
            area: 'Område',
            scatter: 'Spre.',
            areaChart: 'Område',
            settings: 'Innstillinger',
            categories: 'Kategorier',
            defaultCategory: '(Nei)',
            series: 'Serie',
            xyValues: 'X Y-verdier',
            paired: 'Marning modus',
            axis: 'Skulder',
            navigator: 'Navigator.',
            color: 'Farge',
            thickness: 'Tykkelse',
            xTyp: 'X-TYPE',
            automatic: 'Automatisk',
            category: 'Kategori',
        },
        defaultNotTranslated: {
            barChart: 'Barchart.',
            groupedBar: '',
            stackedBar: '',
            normalizedBar: '',
            pieChart: '',
            pie: '',
            doughnut: '',
        },
    },
    companySelection: { title: 'Velg selskap' },
    browserNotSupported:
        '<b>Internet Explorer</b> is not supported. The following browsers are supported: <b>Edge, Chrome, Firefox and Safari',
};
