import { LocalizedCountryNames } from 'i18n-iso-countries';
import { SelectItem } from 'collector-portal-framework/dist/components';
import swedish from './sv';
import norwegian from './no';
import finnish from './fi';

let config: typeof swedish & typeof norwegian & typeof finnish;

switch (window.location.hostname) {
    case '#{hostnameFI}#': // will be replaced in the build process
    case '#{hostnameBankFI}#': // will be replaced in the build process
    case '#{hostnameNorionFI}#': // will be replaced in the build process
        config = finnish;
        break;
    case '#{hostnameNO}#': // will be replaced in the build process
    case '#{hostnameBankNO}#': // will be replaced in the build process
    case '#{hostnameNorionNO}#': // will be replaced in the build process
        config = norwegian;
        break;
    case '#{hostnameSE}#': // will be replaced in the build process
    case '#{hostnameBankSE}#': // will be replaced in the build process
    case '#{hostnameNorionSE}#': // will be replaced in the build process
        config = swedish;
        break;
    case 'localhost':
        config = {
            ...swedish,
            companyLoansComplianceProductId: 88
        };
        break;
    default:
        console.warn(`Configuration for the hostname "${window.location.hostname}" is missing. Fallbacking on swedish config.`);
        config = swedish;
}

const parseCountries = (countries: LocalizedCountryNames): SelectItem[] =>
    Object.keys(countries)
        .map(key => ({ key, label: countries[key] }))
        .sort((a, b) => a.label.localeCompare(b.label, config.locale));

const configWithCountries = {
    ...config,
    countries: parseCountries(config.countries),
};

export default configWithCountries;
