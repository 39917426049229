export default {
    authErrors: {
        correlation_failed: 'Kirjautuminen epäonnistui. Ole hyvä ja kokeile uudestaan.',
        invalid_request:
            'Kirjautuminen epäonnistui. Ole hyvä ja kokeile uudestaan. Huomaa että henkilötunnus täytyy kirjoittaa muodossa PPKKVV-NNNT',
        session_expired: 'Kirjautuminen keskeytyi koska et ole ollut aktiivinen 15 minuuttiin',
    },
    factoring: 'Laskurahoitus',
    agreement: 'Sopimus {0}',
    loanApplicationNumber: 'Hakemusnumero',
    applicant: 'Hakija',
    provisionalInterestRate: 'Korko',
    interestRate: 'Korko',
    guaranteeInterest: '',
    provisionalDuration: 'Takaisinmaksuaika',
    requestedDuration: '',
    grantedDuration: '',
    duration: 'Takaisinmaksuaika',
    processing: '',
    processingDescription: '',
    readyToSign: '',
    readyToSignDescription: '',
    waitingForCompletion: 'Manu',
    supplementaryInfo: '',
    debenture: '',
    approved: '',
    closed: '',
    applicationRejected: '',
    applicationRejectedDescription: '',
    genericError: 'Jokin meni pieleen',
    maintenance: '',
    cancel: 'Peruuta',
    completed: 'Valmis',
    logIn: 'Kirjaudu sisään',
    logInWithBankId: 'Kirjaudu sisään',
    logInWithMobileBankId: 'Kirjaudu sisään',
    logOut: 'Kirjaudu ulos',
    thisWebSiteIsForCorporateCustomers: 'Tämä sivu on suunnattu Norion Bankin yritysasiakkaille',
    customerServicePhoneNumber: '+46 10 161 04 36',
    customerServiceEmail: 'foretagslan@collectorbank.se',
    norionCustomerServiceEmail: 'foretagslan@norionbank.se',
    generalCustomerServicePhoneNumber: '+46 10 - 161 00 00',
    corporateCustomerServicePhoneNumber: '+46 10 - 161 04 36',
    ifYouNeedPrivateHelpDescription: 'Jos sinulla on kysymys, joka koskee sinua yksityishenkilönä, ole hyvä ja soita',
    ifYouNeedCorporateHelpDescription: 'Jos sinulla on kysymyksiä yrityksestäsi tai kiinteistöluotosta, ole hyvä ja ota yhteyttä meihin numerossa',
    orVisitUsAt: 'tai vieraile sivustollamme',
    collectorURL: 'http://collector.fi',
    walleyURL: 'http://walley.fi',
    startPage: 'Yleiskatsaus',
    doYouNeedHelp: 'Onko sinulla kysyttävää? Ota yhteyttä asiakaspalveluumme numeroon',
    or: 'tai sähköpostitse',
    genericConfirmationQuestion: 'Oletko varma että haluat poistaa tämän liitteen?',
    genericConfirmationCancelQuestion: 'Oletko varma että haluat keskeyttää? Syöttämäsi tiedot häviävät, mikäli keskeytät',
    add: 'Lisää',
    addEmail: '',
    save: 'Säästä',
    edit: 'Muuta',
    remove: 'Poista',
    close: 'Sulje',
    month: 'kuukausi',
    months: 'kuukautta',
    personalIdentityNumber: 'Henkilötunnus',
    invalidPersonalIdentityNumber: 'Henkilötunnuksessa on virhe',
    nextStep: 'Tallenna ja siirry eteenpäin',
    nextStepWithoutSaving: 'Siirry eteenpäin',
    yes: 'Kyllä',
    no: 'Ei',
    miscellaneous: 'Yleistä',
    bank: 'Pankki',
    amount: 'Summa',
    currency: 'Valuutta',
    documents: 'Asiakirjat',
    verificationDocuments: '',
    attachments: '',
    signing: 'Allekirjoitus',
    fortnox: '',
    signDebenture: 'Allekirjoitus',
    signFactoringAgreement: '',
    readTheDebenture: '',
    letter: '',
    clCompleteApplicationFor: {
        creditType: {
            DevelopmentLoan: 'Tee loppuun lainahakemus {0}',
        },
    },
    clCompleteApplication: {
        creditType: { DevelopmentLoan: 'Tee loppuun lainahakemus' },
        button: '',
    },
    factApplicationFor: 'Laskurahoitus-hakemus {0}',
    factApplication: 'Laskurahoitus-hakemus',
    dataProtectionDisclaimer: '',
    dataProtectionDisclaimerFactoring: '',
    companyType: 'Yritysmuoto',
    digitallySign: 'Allekirjoita pankkitunnuksilla',
    loan: 'Laina',
    noApplications: {
        DevelopmentLoan: 'Sinulla ei ole lainahakemuksia tällä hetkellä',
    },
    companyLoansUrl: {
        DevelopmentLoan: 'https://www.collector.fi/yritysasiakkaat/yritysrahoitus/',
    },
    readMore: { DevelopmentLoan: 'Lue lisää ' },
    more: 'Enemmän',
    contactPerson: 'Yhteyshenkilö',
    contactPersonDescription: 'Teidän yhteyshenkilönne, johon Collector Bank voi tarvittaessa olla yhteydessä',
    companyAddress: 'Yritysosoite',
    emailInvalid: 'Sähköpostiosoite on syötetty väärässä muodossa',
    telephoneNumberInvalid: '',
    name: 'Nimi',
    emailAddress: 'Sähköpostiosoite',
    emailAddresses: '',
    email: '',
    countryCode: 'Kansalaisuus',
    guarantors: 'Takaajat',
    guarantorDescription: '',
    telephoneNumber: 'Puhelinnumero',
    telephoneNumberIncludingCountryCode: '+358 xx xxx xx xx',
    signatory: '',
    guarantor: 'Takaaja',
    companyLoansApproved: '',
    factApproved: '',
    factSignTitle: 'Allekirjoitus',
    factSign: '',
    factoringUrl: 'https://www.collector.fi/yritysasiakkaat/yritysrahoitus/',
    noCurrentApplications: 'Sinulla ei ole hakemuksia tällä hetkellä',
    factReadMore: 'Lue lisää laskurahoituksesta',
    welcome: 'Tervetuloa!',
    factApplicationExists: 'Sinulla on yksi laskurahoitus-hakemus',
    factMultipleApplications: 'Sinulla on useita laskurahoitus-hakemuksia',
    goToApplications: '',
    goToApplication: 'Siirry hakemukseen',
    bothApplications: '',
    companyLoansSignSuccessful: {
        supplementaryInfo: '',
        deniedAfterSupplementaryInfoSigned: '',
        debenturePartlySigned: '',
        debentureFullySigned: '',
    },
    factoringApplicationSignSuccessful: '',
    factoringTaskSignSuccessful: '',
    signAborted: '',
    addApplicantToAdmin: {
        title: '',
        question: '',
        yes: '',
        no: '',
        finish: '',
        errorHeading: '',
        errorDescription: '',
        moveToSigning: '',
        description: '',
    },
    clSummary: {
        textToSign: '',
        legalIntro: '',
        applicaitonCompleteInfo: '',
        accountNumber: '',
    },
    fileUpload: {
        pleaseAttach: '',
        incomeStatement: '',
        balanceSheet: '',
        uploadMultiple: '',
        notPasswordProtected: '',
        validFileExtensions: '',
        maxFileSize: '',
        uploadFile: '',
        pleaseConsider: '',
    },
    clQuestions: {
        new: '',
        old: '',
        takeOver: '',
        companyType: '',
        loan: '',
        existingLoans: '',
        investingInPremises: '',
        expandInventory: '',
        homepageMarketing: '',
        purchaseOfMachineryOrEquipment: '',
        refinancingOfLoans: '',
        hireStaff: '',
        liquidityForExpansion: '',
        generallyIncreasedLiquidity: '',
        unexpectedExpenses: '',
        businessOverviewStatement: '',
        futurePlans: '',
        loanPurposes: '',
        numberOfEmployees: '',
        turnover: '',
        profit: '',
    },
    factSummary: { textToSign: '', title: '' },
    factQuestions: {
        accountNumber: 'Tilinumero',
        businessDescription: '',
        otherInformation: '',
        otherInformationDescription: '',
        shareOfBusinessCustomers: '',
        averageDueDays: '',
        name: '',
        emailAddress: 'Tilinumero',
        telephoneNumber: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: 'Paikkakunta',
        companyExisted: '',
        turnover: '',
        requestedLimit: '',
        requestedLimitDescription: '',
        percent: '',
        billInAdvance: '',
        changeSellBillInAdvanceInvoices: '',
        billInAdvanceWarning: '',
        beforeDoneInvoice: '',
        days: '',
    },
    factFileUploader: {
        bankgiroScreenshot: { label: '', description: '' },
        balanceSheetYearToDate: { label: '', description: '' },
        incomeStatementYearToDate: { label: '', description: '' },
        annualReportLastYear: { label: '', description: '' },
        confirmationOfPaidCapital: { label: '', description: '' },
        proofOfOwnership: { label: '', description: '' },
    },
    timeOutWarning: {
        heading: 'Tervetuloa',
        body: 'Sinut kirjataan pian ulos. Haluatko pysyä kirjautuneena?',
        confirm: 'Kyllä kiitos, pidä minut kirjautuneena',
        discard: 'Ei kiitos',
    },
    timeOutWarningSessionMaxTime: {
        body: '15 minuutin kuluttua sinut kirjataan ulos, ulos ja takaisin sisään aloittaaksesi uuden istunnon.',
        confirm: 'Ok',
        discard: 'Kirjautua ulos',
    },
    sign: '',
    isSigning: '',
    alreadySigned: 'Hakemus on jo allekirjoitettu',
    signatureComplete: '',
    signatureCompleteButDenied: '',
    debentureComplete: '',
    agreementSignatureComplete: '',
    optional: 'vapaaehtoinen',
    status: 'Tila',
    detailedInfo: 'Tarkemmat tiedot',
    completeLoanApplication: 'Viimeistele lainahakemus',
    completeDigitalFactoring: '',
    completeDigitalFactoringApplication: '',
    moveAlong: 'Jatka',
    previous: 'Edellinen',
    generalInfo: '',
    complianceQuestions: '',
    reviewAndComplete: '',
    almostFinished: {
        creditType: { DevelopmentLoan: '' },
        description: '',
    },
    almostFinishedFactoring: '',
    requestedLoanAmount: '',
    grantedLoanAmount: '',
    provisionalGrantedLoanAmount: '',
    companyName: 'yrityksen nimi',
    organisationNumber: 'Yritystunnus',
    type: 'Tapahtuma',
    signatoryCombinations: {
        signatories: '',
        choiceOfSignatories: '',
        chooseSignatoriesPlaceholder: '',
        chooseConstellation: '',
        contactInfoHeading: '',
        noCombinationsFound: '',
        procuration: '',
    },
    loanApplications: 'Lainahakemukset',
    applications: '',
    adviceMethod: '',
    companyLoanDetails: {
        companyDetails: {
            title: 'Selskapsdetaljer',
            contactPerson: '',
            choseAdviceMethod: '',
            adviceEmail: '',
        },
        invoices: 'Laskut',
        denyOthers: {
            title: '',
            cancel: '',
            description: '',
            descriptionWithCompany: '',
            approve: '',
        },
        upcoming: 'tulevat',
        notified: 'Laskutettu',
        ocrNumber: 'Viitenumero',
        elapsedAmount: 'Erääntynyt summa',
        capitalDebt: 'Velkapääoma',
        originalAmount: 'Alkuperäinen summa',
        guaranteeAmount: 'Takaussumma',
        currentDebt: 'Tämänhetkinen',
        registrationNumber: 'Lainanumero',
        facilityFee: 'Palvelumaksu',
        creditNumber: 'Sopimusnumero',
        signedDate: 'Sopimuspäivä',
        endDate: 'Lopetuspäivä',
        appliedThrough: '',
        overdueInvoicesHeading: 'Erääntynyt lasku',
        overdueInvoicesMessage: 'Sinulla on erääntyneenä {0}. Maksa erääntynyt summa viipymättä välttyäksesi lisäkuluilta.',
        invoicingInfo: '',
        invoicingMethod: '',
        invoicingRecipients: '',
        invoicingUpdateFailed: '',
        invoicingWarning: '',
        changeInvoicingMethod: '',
        loanStatuses: {
            Created: 'Rekisteröity',
            Activated: 'Aktiivinen',
            Terminated: 'Suljettu',
            MarkedForDebtCollection: 'Siirtynyt perintään',
            PendingTermination: 'Irtisanottu',
        },
        capitalOverview: {
            show: '',
            title: '',
            stopShow: '',
            Amortisation: '',
            Disbursement: '',
            CapitalizedInterest: '',
            CapitalizedFacilityFee: '',
            debt: '',
        },
        invoicePaymentStatuses: {
            Unpaid: 'Maksamaton',
            PartlyPaid: 'Maksettu osittain',
            Paid: 'Maksettu',
            Credited: 'Hyvitetty',
        },
        invoiceStatuses: {
            NotNotified: 'Ei laskutettu',
            Notified: 'Laskutettu',
            Overdue: 'Erääntynyt',
            ReminderSent: 'Muistutus lähetettiin ',
        },
        invoiceCopy: {
            buttonText: '',
            title: '',
            send: '',
            failed: '',
            success: '',
            successDetails: '',
            maximalAmountReached: '',
        },
        isDue: 'Eräntyy',
        leftToPay: 'Jäljellä oleva summa:',
        invoiceDetails: { description: 'Kuvaus', type: 'Tapahtuma', amount: 'Summa' },
        itemType: {
            HandlingFee: 'Käsittelymaksu',
            ArrangementFee: 'Järjestelypalkkio',
            PrepaymentFee: 'Ennenaikaisen lunastamisen maksu',
            OtherFee: 'Muu maksu',
            FacilityFee: 'Palvelumaksu',
            ReminderFee: 'Muistutusmaksu',
            Expense: 'Kulut',
            Installment: 'Lyhennys',
            Interest: 'Korko',
            PenaltyInterest: 'Viivästyskorko',
            Rounding: 'Pyöristäminen',
            Disbursement: 'Maksettu lainasumma',
            Payment: 'Maksusuoritus',
            Notification: 'Lainanhoitokulu',
            Aconto: 'Kohdistamaton liikasuoritus',
            GuaranteeInterest: '',
        },
        creditType: {
            Company: 'Yrityslaina',
            Property: 'Kiinteistölaina',
            EntryLoan: 'Laina',
            EmergencyLoan: 'Laina',
            DevelopmentLoan: 'Laina',
            ECommerceLoan: 'Laina',
            LeaseLoan: 'Laina',
            PropertyPrivate: 'Laina',
            PrivateCredit: 'Laina',
            EmployeeCredit: 'Laina',
            BankGuarantee: 'Pankkitakaus',
            BankGuaranteeProperty: "Pankkitakaus (Kiinteistö)"
        },
        creditStatus: { Draft: 'Luonnos', Active: 'Aktiivinen', Closed: 'Suljettu' },
        noNotifiedInvoices: 'Teillä ei ole maksettavia laskuja',
        noNotNotifiedInvoices: 'Teillä ei ole tulevia laskuja',
        transactionTab: { history: 'Historia', withdrawal: '', stopWithdrawal: '' },
    },
    clTransactions: { noTransactions: '' },
    clTransactionReport: {
        title: '',
        downloadText: '',
        csv: { date: '', type: '', amount: '', currency: '' },
        type: { Payment: '', Disbursement: '' },
    },
    stopWithdrawal: {
        heading: '',
        question: '',
        confirm: '',
        discard: '',
        toolTip: { CancellationInitiated: '', CancellationFailed: '' },
        done: '',
        info: '',
        error: '',
        errorHeading: '',
    },
    withdrawalQuestions: {
        dateSelect: { asap: '', other: '', future: '' },
        date: { label: '' },
        withDrawalAmount: '',
        toAccount: '',
        submit: '',
        noAccount: '',
        confirm: '',
        cancel: '',
        confirmQuestion: '',
        completed: { approved: '', receipt: '', done: '' },
        error: { denied: '' },
        disabled: '',
    },
    disbursementStatus: {
        Initiated: '',
        CancellationFailed: '',
        CancellationInitiated: '',
        Completed: '',
        Cancelled: '',
        Denied: '',
        Failed: '',
    },
    documentsView: {
        noDocuments: 'Sinulla ei ole tällä hetkellä vuosiraporttia',
        engagementReportsHeader: 'Vastuuerittely',
        noEngagements: '',
        download: 'Lataa',
        companySelect: 'Valitse liiketoiminta',
        selectToDate: 'Päivämäärälle',
        includeTerminated: 'Sisällytä valmiit lainat',
        agreements: '',
        selectFromDate: '',
        includeFromDate: '',
        language: 'Kieli',
        swedish: 'Routsin',
        norwegian: 'Norjan',
        finnish: 'Soumalainen',
        english: 'Englanti',
    },
    userView: {
        noActiveCompanies: '',
        pageHeader: '',
        heading: '',
        companyHeading: '',
        role: { Signatory: '', Admin: '' },
        addUser: '',
        duplicateUser: '',
        confirmation: '',
        userConfirmationQuestion: '',
        telephoneNumberInvalid: '',
    },
    fortnoxApiKey: {
        apiKey: '',
        connect: '',
        approvedHeader: '',
        approved: '',
        information: '',
        informationLink: '',
        error: '',
        apikeyError: '',
        pleaseWait: '',
    },
    loading: 'Lataa..',
    sieUploader: {
        instructions: '',
        manualUpload: '',
        cancelManual: '',
        heading: '',
        success: '',
        errorParsingSieFile: '',
        tooOldIncomeStatement: '',
    },
    saftUploader: {
        instructions: '',
        manualUpload: '',
        cancelManual: '',
        heading: '',
        success: '',
        errorParsingSaftFile: '',
        tooOldIncomeStatement: '',
    },
    fileUploader: {
        error: {
            tooOldIncomeStatement: '',
            errorParsingSaftFile: '',
            errorParsingSieFile: '',
            failedToRemove: '',
        },
    },
    companyLoanTransactionType: { Disbursement: 'Maksettu lainasumma', Payment: 'Maksusuoritus' },
    transactions: 'Tilitapahtumat',
    factClient: {
        client: '',
        clientSummary: '',
        limit: { unusedLimit: '', usedLimit: '' },
        summary: {
            totalLimit: '',
            debtAmount: '',
            openCreditnotes: '',
            openCreditnotesDescription: '',
            myInvoices: '',
            invoiceStatus: '',
        },
        factoringType: {
            buyAmount: '',
            recourseAmount: '',
            recoursedAmount: '',
            serviceAmount: '',
            Buy: '',
            Denied: '',
            Recourse: '',
            Service: '',
        },
        invoiceStatus: {
            openAmount: '',
            expiredInvoicesAmount: '',
            expiredInvoicesWithRemindersSentAmount: '',
            debtCollectionAmount: '',
            open: '',
            closed: '',
        },
        creditnote: '',
        invoices: 'Laskut',
        invoiceDetails: { description: 'Kuvaus', itemNumber: '', amount: 'Summa' },
        transactionCodes: {
            '1000': '',
            '1001': '',
            '1002': '',
            '1003': '',
            '1004': '',
            '1005': '',
            '1006': '',
            '1007': '',
            '1008': '',
            '1009': '',
            '1010': '',
            '1011': '',
            '1012': '',
            '1013': '',
            '1014': '',
            '1015': '',
            '1016': '',
        },
        transactionList: '',
        transactionDetails: {
            reference: '',
            information: '',
            transactionType: '',
            currencyCode: '',
            postingDate: '',
            transactionCode: '',
            amount: '',
            downloadCsv: '',
        },
        noSoldInvoices: '',
        noTransactions: '',
        invoiceReasonCode: {
            '1001': '',
            '1002': '',
            '1003': '',
            '1004': '',
            '1005': '',
            '1006': '',
            '1007': '',
            '1008': '',
            '1009': '',
            '2009': '',
            heading: '',
        },
        transactions: { searchTransactions: '', filterTransactionType: '' },
        status: {
            Active: '',
            Blocked: '',
            Terminated: '',
            blockedInfo: '',
            terminatedInfo: '',
        },
        invoiceSearchAndFilter: {
            invoiceStatusFilterLabel: '',
            invoiceTypeFilterLabel: '',
            searchLabel: '',
        },
        sortHeader: '',
        sortByInvoiceDateDesc: '',
        sortByInvoiceDateAsc: '',
        sortByDueDateDesc: '',
        sortByDueDateAsc: '',
        factoringTypeText: { Buy: '', Denied: '', Recourse: '', Service: '' },
        getInvoiceCopy: '',
        attachment: '',
        reminder: '',
        getFiles: '',
        missingFiles: '',
        files: '',
        invoice: '',
        clearSearch: '',
    },
    registerAccountNumber: {
        chooseBankAccount: '',
        enterGiro: '',
        enterManually: '',
        chooseFromList: '',
        chooseAccount: '',
        chooseAccountType: '',
        clearingNumber: '',
        accountNumber: '',
        accountInvalid: '',
        accountCouldNotBeVerified: '',
        documentDescription: '',
        documentHeading: '',
        pageHeading: '',
        informationBox: '',
    },
    registerBankAccount: '',
    companyLoansAccountType: {
        prefixes: { 'SE-BA': '', 'SE-BG': '', 'SE-PG': '', 'NO-BA': '', IBAN: '' },
        label: '',
    },
    from: '',
    to: '',
    invoiceDate: '',
    dueDate: '',
    recourseDate: '',
    hideFilter: '',
    showFilter: '',
    orderId: '',
    factAgreement: {
        document: {
            'Fakturaköpsavtal Collector Bank': '',
            'Fakturakjøpsavtale Collector Bank': '',
            'Avtal inkassotjänster Colligent': '',
            'Avtale om inkassotjenster Colligent': '',
            'Kundeavtale 24SevenFinans AS for 24SevenOffice kunder': '',
            'Kundavtal 24SevenFinans AS': '',
        },
    },
    users: '',
    address: {
        street: '',
        street2: '',
        postalCode: '',
        city: '',
        country: '',
        careOf: '',
    },
    update: '',
    rawAddress: '',
    factInvoice: {
        actions: {
            stopDebtCollectionAction: '',
            releaseDebtCollectionAction: '',
            changeDueDateAction: '',
            registerDirectPayment: '',
            title: '',
        },
        stopDebtCollection: {
            header: '',
            confirmText: '',
            commentFieldLabel: '',
            confirmButton: '',
            cancelButton: '',
            stopped: '',
            stoppRequested: '',
            releaseRequested: '',
            confirmationMessage: '',
        },
        releaseDebtCollection: {
            header: '',
            confirmText: '',
            confirmButton: '',
            cancelButton: '',
            confirmationMessage: '',
        },
        changeDueDate: {
            header: '',
            confirmText: '',
            confirmButton: '',
            cancelButton: '',
            confirmationMessage: '',
            dueDate: '',
            pending: '',
        },
        registerDirectPayment: {
            header: '',
            confirmText: '',
            confirmButton: '',
            cancelButton: '',
            confirmationMessage: '',
            paymentDate: '',
            pending: '',
            error: '',
        },
        error: { errorHeader: '', errorText: '' },
        BlockFromDebtCollectionRequested: '',
        BlockedFromDebtCollection: '',
        BlockFromDebtCollectionAborted: '',
        BlockFromDebtCollectionFailed: '',
        ReleaseForDebtCollectionRequested: '',
        ReleasedForDebtCollection: '',
        ReleaseForDebtCollectionAborted: '',
        ReleaseForDebtCollectionFailed: '',
        PostponeDueDateRequested: '',
        PostponeDueDateAborted: '',
        PostponeDueDateFailed: '',
        DueDatePostponed: '',
        RegistrationOfDirectPaymentRequested: '',
        RegistrationOfDirectPaymentAborted: '',
        RegistrationOfDirectPaymentFailed: '',
        DirectPaymentRegistered: '',
        changeStatus: { None: '', ChangesInProgress: '' },
        statusTitle: { directPayment: '', postponeDuedate: '' },
        directlyPaid: '',
        event: '',
        events: '',
        missingEvents: '',
        backwards: '',
    },
    backwards: '',
    agGrid: {
        invoiceStatus: { Open: '', Closed: '', Pending: '', Cancelled: '' },
        type: { invoice: '', credit: '' },
        invoiceTable: {
            status: '',
            number: '',
            factoringDate: '',
            amount: '',
            remainingAmount: '',
            dueDate: '',
            pendingChanges: '',
            overdue: '',
            organizationNumber: '',
            endCustomer: '',
            type: '',
            factoringType: '',
            resetFilter: '',
        },
        debtCollectionStatus: { InProgressBlock: '', InProgressUnblock: '' },
        postPoneStatus: { ChangesInProgress: '' },
        directPayment: { ChangesInProgress: '' },
        default: {
            selectAll: '',
            selectAllSearchResults: '',
            searchOoo: '',
            blanks: '',
            noMatches: '',
            filterOoo: '',
            equals: '',
            notEqual: '',
            empty: '',
            lessThan: '',
            greaterThan: '',
            lessThanOrEqual: '',
            greaterThanOrEqual: '',
            inRange: '',
            inRangeStart: '',
            inRangeEnd: '',
            contains: '',
            notContains: '',
            startsWith: '',
            endsWith: '',
            dateFormatOoo: '',
            andCondition: '',
            orCondition: '',
            applyFilter: '',
            resetFilter: '',
            clearFilter: '',
            cancelFilter: '',
            textFilter: '',
            numberFilter: '',
            dateFilter: '',
            setFilter: '',
            columns: '',
            filters: '',
            pivotMode: '',
            groups: '',
            rowGroupColumnsEmptyMessage: '',
            values: '',
            valueColumnsEmptyMessage: '',
            pivots: '',
            pivotColumnsEmptyMessage: '',
            loadingOoo: '',
            noRowsToShow: '',
            enabled: '',
            pinColumn: '',
            pinLeft: '',
            pinRight: '',
            noPin: '',
            valueAggregation: '',
            autoSizeThisColumn: '',
            autosizeThiscolumn: '',
            autosizeAllColumns: '',
            groupBy: '',
            ungroupBy: '',
            resetColumns: '',
            expandAll: '',
            collapseAll: '',
            copy: '',
            ctrlC: '',
            copyWithHeaders: '',
            paste: '',
            ctrlV: '',
            export: '',
            csvExport: '',
            excelExport: '',
            excelXmlExport: '',
            sum: '',
            min: '',
            max: '',
            none: '',
            count: '',
            avg: '',
            filteredRows: '',
            selectedRows: '',
            totalRows: '',
            totalAndFilteredRows: '',
            more: '',
            to: '',
            of: '',
            page: '',
            nextPage: '',
            lastPage: '',
            firstPage: '',
            previousPage: '',
            pivotChartAndPivotMode: '',
            pivotChart: '',
            chartRange: '',
            columnChart: '',
            groupedColumn: '',
            stackedColumn: '',
            normalizedColumn: '',
            line: '',
            area: '',
            scatter: '',
            areaChart: '',
            settings: '',
            categories: '',
            defaultCategory: '',
            series: '',
            xyValues: '',
            paired: '',
            axis: '',
            navigator: '',
            color: '',
            thickness: '',
            xTyp: '',
            automatic: '',
            category: '',
        },
        defaultNotTranslated: {
            barChart: '',
            groupedBar: '',
            stackedBar: '',
            normalizedBar: '',
            pieChart: '',
            pie: '',
            doughnut: '',
        },
    },
    companySelection: { title: '' },
    browserNotSupported:
        '<b>Internet Explorer</b> is not supported. The following browsers are supported: <b>Edge, Chrome, Firefox and Safari',
};
